.chanel_partner_form {
	padding: 8.5rem 0;

	// background-color: #191919;
	.heading {
		text-align: center;

		h2 {
			color: @secondary-color;
			font-family: @mild-font;
			font-size: 5rem;
			font-weight: 500;
			margin: 0;
		}
	}

	.form_section {
		margin: 50px 0;
	}

	@media screen and (max-width:768px) {
		padding: 2rem 0;
	}
}

.summary_card {
	.card_title {
		background-color: #191919;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1rem 3rem;
		margin-bottom: 3rem;
		margin-top: -20px;
		margin-left: -20px;
		width: calc(100% + 40px);

		>* {
			text-decoration: none;
			color: @white-color;
			font-weight: 600;
			margin: 0;
			font-size: 2rem;
		}
	}

	.form {
		margin-bottom: 3rem;
		box-shadow: 0 2px 1.4rem 0 rgba(0, 0, 0, .15);
		padding: 2rem 2rem;

		.ant-input-number {
			width: 100%;
			height: auto;
			background-color: transparent;
			border: none;

			.ant-input-number-handler-wrap {
				display: none;
			}
		}

		.ant-form-item-row {
			margin: 10px 0;
		}

		label {
			font-size: 1.5rem;
			color: rgba(0, 0, 0, 0.7);
			padding-left: 1.5rem;
			padding-right: 1.5rem;
			width: 100%;
			font-family: @forth-banner-font;

			&.ant-form-item-required::before {
				color: #c00;
				content: "*";
				display: inline-block;
				font-family: SimSun, sans-serif;
				font-size: 16px;
				line-height: 1;
				margin-right: 4px;
			}
		}

		.ant-form-item-label {
			width: 100%;

			@media screen and(max-width:480px) {
				label {
					padding-left: 0rem;
				}
			}
		}

		.ant-input,
		textarea,
		.ant-input-number-input {
			background-color: transparent;
			border: none;
			color: @secondary-color;
			font-size: 1.5rem;
			line-height: normal;
			padding: 0.8rem 1.3rem;
			border-bottom: solid 1px rgba(0, 0, 0, 0.5);
			position: relative;
			outline: none;
			resize: none;
			height: auto;
			border-radius: 0.8rem;
			border: 1px solid #d0d5dd;
			transition: all 0.5s ease-in-out;
			box-shadow: 0 0 30px 0 rgba(0, 0, 0, .06);

			&:focus {
				box-shadow: 0 0 0 2px rgba(237, 0, 140, .2);
			}

			&::-webkit-input-placeholder {
				/* WebKit, Blink, Edge */
				color: inherit;
			}

			&:-moz-placeholder {
				/* Mozilla Firefox 4 to 18 */
				color: inherit;
			}

			&:-ms-input-placeholder {
				/* Internet Explorer 10-11 */
				color: inherit;
			}

			&::placeholder {
				font-family: @secondary-font;
				color: #bfbfbf !important;
			}
		}
	}

	.button {
		text-align: center;
	}
}

.list_descreption {
	margin-top: 2rem;
	font-size: 2rem;

	ul {
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		gap: 1em 0;
		width: 100%;
		max-width: 900px;
		margin: 0 auto;
	}

	li {
		width: 100%;
		padding: 0.05em 1.5em;
		position: relative;

		&::before {
			background-color: #000;
			border-radius: 50%;
			content: "";
			display: inline-block;
			height: 6px;
			left: 6px;
			position: absolute;
			top: 8px;
			width: 6px;
		}
	}
}

.captchWrap {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	width: 100%;

	input {
		background-color: transparent;
		border: none;
		border-radius: 0;
		color: @secondary-color;
		font-size: 1.5rem;
		line-height: normal;
		padding: 1rem 1rem;
		border-bottom: solid 1px rgba(0, 0, 0, 0.5);
		position: relative;
		outline: none;
		resize: none;
		box-shadow: none !important;
		height: auto;

		&:focus {
			border-color: #ed008c;
		}

	}

	.captchaRelative {
		position: relative;
		display: flex;
		align-items: center;
		width: 50%;

		.captchaCode {
			width: 100%;
		}

		.ant-input[disabled] {
			background-color: transparent;
			border: none;
			padding: 0;
			color: #000;
			font-weight: 500;
		}
		.ant-form-item-control-input {
			.ant-form-item-control-input-content {
				width: 50%;
				position: relative;

				.ant-input {
					background-color: #f1f1f1;
					height: 40px;
					padding: 6px 10px;
				}
			}
		}

		.reloadIcon {
			position: absolute;
			top: 45px;
			cursor: pointer;
			right: 25px;

			svg {
				width: 20px;
				height: 20px;
			}
		}

		@media screen and (max-width:380px) {
			width: 100%;
			margin: 0;
			.captchaCode {
			margin: 0;
			}
		}
	}

	.recaptchaText {
		width: 50%;
		margin-top: 26px;
		padding-right: 10px;

		input {
			padding: 0;
		}

		.ant-form-item-control {
			padding: 0;
		}

		label {
			font-family: @forth-banner-font;
			display: none;
		}
		@media screen and (max-width:380px) {
			width: 100%;
			margin: 0;
			padding: 0 5px;
		}
	}
}

.fra_inquiry_details_form {
	.form label, .ant-form-vertical .ant-form-item .ant-form-item-control {
		padding: 0;
	} 
	.ant-picker{
		width: 100%;
		padding: 8px 13px;
	}

	form label:has(.ant-form-item-optional):before{
		display: none;
	}
	form label .ant-form-item-optional{
		display: none;
	}
	.ant-form-item.inputText {
		margin-bottom: 10px;
	}
	.ant-divider.ant-divider-horizontal {
		margin: 16px 0 8px 0;
	}
}
.kycFileUpload {
	border: solid #e0e0e0;
	border-width: 1px 1px 3px;
	padding: 10px;
	margin: 0 0 10px 0 !important;
  }
  
  .kycFileUpload .ant-col {
	margin-bottom: 0 !important;
  }
  
  .kycFileUpload .upload_left_sec ul {
	margin-bottom: 0;
  }
  
  .kycFileUpload .upload-list-inline .upload_file {
	height: 80px;
	line-height: 1.2;
	min-width: 145px;
	background: #fafafa;
  }
  
  .kycFileUpload .upload-list-inline .upload_file svg {
	width: 50px;
	height: 20px;
  }
  
  .kycFileUpload .upload-list-inline .upload_file span {
	display: block;
	font-size: 14px;
	margin-top: 4px;
  }

  .kycFileUploadView {
	margin-bottom: 20px !important;
  
	label {
	  width: 100%;
	  display: block;
	  margin-bottom: 5px;
	}
  
	.ant-image {
	  margin-right: 10px;
	  border: 1px solid #c2c2c2;
  
	  img {
		height: 70px;
		object-fit: cover;
	  }
	}
  }
  .searchBtn {
	.ant-form-item-control-input-content {
		max-height: 0px;
		margin-top: 19px;
		height: 42px
	}
  }
  .deleteModal {
	
	.ant-modal-header {
		text-align: center;
		border-radius: 0;
		padding: 10px;
	  border-top: 6px solid @danger_color;
	  .ant-modal-title {
		font-size: 16px;
		padding-right: 10px;
		color: #000;
	}
	}
	
	.ant-form>.ant-row {
	  &>.ant-col {
		margin-bottom: 25px;
	  }
	}
  
	.ant-modal-body {
	  h3 {
		color: @input_label_color;
  
		span {
		  color: @secondary-color;
  
		  svg {
			color: @danger_color;
			margin-right: 10px;
			font-size: 22px;
		  }
		}
	  }
	}
  
	.ant-modal-footer {
		border-top: 0px solid #e0e0e0;
		padding: 20px;
		text-align: center;
		background-color: #f3f4ff;
		button {
			padding: 10px 20px;
			height: auto;
			border-radius: 5px;
			font-size: 16px;
			line-height: normal;
			min-width: 100px;
			margin-left: 0;
		  }
	  .cancelBtn {
		border-color: @danger_color;
		color: @danger_color;
		// @include themeColorOpacity($danger_color, 0.1);
	  }
		.ant-btn-dangerous{
			letter-spacing: inherit;
			text-transform: capitalize;
		}
	  .ant-form-item {
		margin-bottom: 0;
	  }
	}
  
	.ant-modal-close-x {
	  svg {
		color: @danger_color;
  
		&:hover {
		  color: @danger_color;
		}
	  }
	}
  }
  .deleteModal .ant-modal-header {
	border-top: 6px solid #ff3131;
  }
  
  .deleteModal .ant-form > .ant-row > .ant-col {
	margin-bottom: 25px;
  }
  
  .deleteModal .ant-modal-body h3 {
	color: #444444;
  }
  
  .deleteModal .ant-modal-body h3 span {
	color: #141414;
  }
  
  .deleteModal .ant-modal-body h3 span svg {
	color: #ff3131;
	margin-right: 10px;
	font-size: 22px;
  }
  
  .deleteModal .ant-modal-footer .cancelBtn {
	border-color: #ff3131;
	color: #ff3131;
	background-color: rgba(255, 49, 49, 0.1);
  }
  
  .deleteModal .ant-modal-footer .ant-form-item {
	margin-bottom: 0;
  }
  
  .deleteModal .ant-modal-close-x svg {
	color: #ff3131;
  }
  
  .deleteModal .ant-modal-close-x svg:hover {
	color: #ff3131;
  } 