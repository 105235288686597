.visionContent {
	.contentPera {
		font-size: 2.2rem;
		font-family: @secondary-font;
		font-weight: 300;

		.boldPera {
			font-weight: 500 !important;
		}

		b {
			font-size: 2.2rem;
			font-family: @secondary-font;
			font-weight: 500;
			@media screen and (max-width: 800px) {
				font-size: 1.8rem;
			}
			@media screen and (max-width: 480px) {
			font-size: 1.4rem;
			}
		}
	}

	.centerBottomImg {
		position: absolute;
		top: -10rem;
		right: 0;
	}
}

.tabContent {
	.ant-segmented {
		width: 100%;
		background-color: transparent !important;

		.ant-segmented-item-label {
			padding: 0px 0px;
		}

		.ant-segmented-group {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			.ant-segmented-item {
				width: 80%;
				transform: scale(0.9);
				.transition(0.7s, ease-in-out);
				margin-bottom: 1.5rem;
				box-shadow: 0px 0px 4rem 0px rgba(0, 0, 0, 0.1);
				border: 1px solid transparent;

				@media screen and (max-width: 1400px) {
					margin-bottom: 2.2rem;
				}

				@media screen and (max-width: 800px) {
					width: 92%;
				}
			}
		}

		.ant-segmented-thumb {
			box-shadow: none;
		}

		.ant-card {
			border: none;

			.ant-card-body {
				display: flex;
				align-items: center;
				padding: 2.2rem 5rem;

				h3 {
					color: #000;
					font-size: 2.4rem;
					font-family: @primary-font;
					text-align: left;
					font-weight: 600;
					margin-bottom: 0px;

					@media screen and (max-width: 1400px) {
						font-size: 2rem;
					}

					@media screen and (max-width: 480px) {
						font-size: 1.7rem;
					}
				}

				p {
					text-align: left;
					color: #000;
					font-size: 1.8rem;
					font-family: @secondary-font;
					font-weight: 300;
					word-break: normal;
					white-space: normal;
					margin-top: 0px;
					margin-bottom: 0px;
					line-height: 1.3;
					@media screen and (max-width: 1400px) {
						// font-size: 1.6rem;
						margin-bottom: 0;
					}

					@media screen and (max-width: 1024px) {
						//font-size: 14px;
						margin-bottom: 0;
						padding-top: 0;
					}

					// @media screen and (max-width: 480px) {
					// 	font-size: 1.2rem;
					// }
				}

				img {
					@media screen and (max-width: 1024px) {
						height: 4rem;
						width: 4rem;
					}
					@media screen and (max-width:480px) {
						height: 3.5rem;
						width: 3.5rem;
					}
					
				}

				@media screen and (max-width: 1400px) {
					padding: 1.5rem 3rem;
				}

				@media screen and (max-width: 1024px) {
					padding: 1.5rem 2.5rem;
				}

				@media screen and (max-width: 480px) {
					padding: 0.5rem 1rem;
				}
			}

			.cardWrap {
				width: calc(100% - 7rem);
				margin-left: 3rem;
				@media screen and (max-width: 480px) {
					width: calc(100% - 3rem);
				margin-left:1rem;
				}
				h2{
					text-align: left;
					font-size:2rem;
					color:#000;
					font-family:@primary-font;
				}
			}
		}

		.ant-segmented-item-selected {
			transform: scale(1.1) !important;
			box-shadow: 0px 0px 5rem 0px rgba(0, 0, 0, 0.1);
			border: 1px solid #e1e1e1 !important;
		}

		&.segmentedComp {
			.ant-segmented-group {
				background-color: #fff;
				border-radius: 0.8rem;
				box-shadow: 0 0 1rem -0.5rem rgba(0, 0, 0, 0.5);
				display: grid;
				grid-template-columns: 1fr;

				.ant-segmented-item {
					border-radius: 0.8rem;
					font-size: 1.4rem;
					font-weight: 600;
					padding: 0.5rem 2rem;
					transition: all 0.3s linear;
					width: 100%;

					&:hover,
					&:focus {
						background-color: #0fadf7;
						color: #fff;
					}
				}

				.ant-segmented-thumb,
				.ant-segmented-item-selected {
					border-radius: 8px;
					background-color: #0791d2;
					color: #fff;

					&:hover,
					&:focus {
						background-color: #0791d2;
					}
				}
			}
		}
	}

	.visionContent {
		.transition(0.7s, linear);

		.contentPera {
			@media screen and (max-width: 480px) {
				margin-bottom: 2rem;
			}

			p {
				@media screen and (max-width: 1024px) {
					margin-bottom: 0;
				}
			}
		}

		.headingSpan {
			&:before {
				right: -9.8rem;
			}

			&:before {
				right: -13.1rem;

				@media screen and (max-width: 1024px) {
					right: -8rem;
				}

				@media screen and (max-width: 600px) {
					right: -5.6rem;
				}

				@media screen and (max-width: 480px) {
					right: -6.4rem;
				}
			}
		}
	}
}
