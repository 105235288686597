// .home{
// 	&.serviceContainer {	
// 		margin-top: 0;
// }
// }
.serviceContainer {
	padding: 10rem 0;
	// @media screen and (max-width: 600px){
	// 	margin-top: 8rem;
	// }
	@media screen and (max-width:767px) {
		padding: 5rem 0 5rem;
	}
	.boxImg {
		position: absolute;
		right: 16rem;
		img {
			width: 9rem;
			height: 9rem;
		}
		@media screen and (max-width: 1600px) {
			right: 5rem;
		}
	}
	.headerWrap {
		display: flex;
		align-items: center;
		width: 100%;

		@media screen and(max-width:800px) {
			flex-wrap: wrap;
		}

		.peraCol {
			justify-content: space-between;
			display: flex;
			position: relative;

			.boxImg {
				position: absolute;
				top: -4.5rem;
				bottom: 0;
				right: 0;

				img {
					height: 9.5rem;
					width: 9.5rem;
				}
			}
		}
	}

	.cardRow {
		margin-top: 3rem;
		.ant-col {
			padding-left: 0;
			padding-right: 0;
		}
		.ant-card {
			height: 100%;
			box-shadow: 0px 0px 12rem 0px rgba(0, 0, 0, 0.06);
			.transition(0.8s, ease-in-out);
			border: none;
			transform: scale(1);
			margin: 0px 0px 0px 0px;

			.ant-card-body {
				padding: 35px 55px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				.icon {
					padding: 0px 0px;
					height: 100px;
					display: flex;
					align-items: flex-end;
					img {
						height: 100%;
					}
					@media screen and(max-width:992px) {
						height: 85px;
					}

				}
				@media screen and(max-width:1390px) {
					padding: 2.5rem;
				}

				@media screen and(max-width:1260px) {
					padding: 2rem;
				}

				.cardTitle {
					font-family: @primary-font;
					font-size: 3rem;
					font-weight: 600;
					color: @secondary-color;
					text-align: center;
					margin-top: 1.7rem;

					@media screen and (max-width: 1400px) {
						font-size: 2.8rem;
					}

					@media screen and (max-width: 1260px) {
						font-size: 2.1rem;
					}				
				}

				.cardPera {
					p {
						text-align: center;
						font-family: @secondary-font;
						font-weight: 300;
						color: @text-input-color;
						font-size: 2rem;

						@media screen and (max-width: 1600px) {
							font-size: 1.8rem;
						}

						// @media screen and (max-width: 1200px) {
						// 	font-size: 1.5rem;
						// }

						// @media screen and (max-width: 320px) {
						// 	font-size: 1.3rem;
						// }
					}
				}

				@media screen and (max-width: 1024px) {
					padding: 2.5rem;
				}

				@media screen and(max-width:480px) {
					padding: 1.5rem;
				}
			}

			&:hover {
				transform: scale(1.05);
				z-index: 2;

				.readMoreLink {
					color: @primary-color;

					span {
						svg {
							path {
								fill: @primary-color;
							}
						}
					}

					&::after {
						background-color: @primary-color;
					}
				}
			}
			.cardFooter {
				position: absolute;
				bottom: 15px;
			}
			@media screen and (max-width:480px) {
				padding: 2rem;
			}
		}

		@media screen and (max-width: 800px) {
			.ant-col-8 {
				padding-top: 0px !important;
			}
		}
	}

	@media screen and (max-width: 768px) {
		.peraCol {
			padding-top: 0px !important;
		}

		.col-100 {
			padding-top: 0px !important;
		}
	}
}

.simpleGuide {
	padding: 10rem 0;
	@media screen and (max-width:600px) {
		padding: 5rem 0 7rem;
	}
	.Content {
		@media screen and (max-width: 1024px) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	.cardExtra {
		@media screen and (max-width: 1024px) {
			&.ant-col-12 {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
	}

	.cardwrap {
		display: flex;
		flex-wrap: wrap;
		.ant-col {
			padding-left: 0;
			padding-right: 0;
			margin-bottom: 1.5rem;
		}
		.ant-card {
			margin-top: 2rem;

			.cardTitle h5 {
				color: #000;
				text-align: center;
				font-family: @primary-font;
				font-size: 2.5rem;

				font-weight: 600;
				// @media screen and (max-width: 1104px) {
				// 	font-size: 1.7rem;
				// }
			}

			.cardPera {
				color: #000;
				text-align: center;
				font-family: @secondary-font;
				font-size: 2.2rem;

				font-weight: 300;
				ul {
					padding-left: 20px;
					text-align: left;
					li {
						list-style: disc;
					}
				}
			}
		}

		.ant-card {
			height: 100%;
			box-shadow: 0px 0px 12rem 0px rgba(0, 0, 0, 0.06);
			.transition(0.8s, ease-in-out);
			border: none;
			//	transform: scale(0.96);
			margin: 0px;

			.ant-card-body {
				padding: 3rem 2rem 0;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;

				@media screen and(max-width:1390px) {
					padding: 2.5rem;
				}

				@media screen and(max-width:1260px) {
					padding: 2rem;
				}

				.icon {
					padding: 0rem 0px;
					width: 85px;
					height: 85px;
					margin-bottom: 2rem;
					svg,
					img {
						height: 10rem;
						width: 10rem;
						@media screen and (max-width: 1200px) {
							height: 7.5rem;
							width: 7.5rem;
						}
						@media screen and (max-width: 1024px) {
							height: 7rem;
							width: 7rem;
						}
					}
					// @media screen and (max-width: 1024px) {
					// 	padding: 2.5rem 0px;
					// }
				}

				.cardTitle {
					font-family: @primary-font;
					font-size: 2.2rem;
					font-weight: 600;
					color: @secondary-color;
					margin-bottom: 0;

					@media screen and (max-width: 1400px) {
						font-size: 2.8rem;
					}

					@media screen and (max-width: 1260px) {
						font-size: 1.8rem;
					}
					@media screen and (max-width: 1104px) {
						font-size: 1.7rem;
					}
					@media screen and (max-width: 1024px) {
						font-size: 1.9rem;
					}

					@media screen and (max-width: 480px) {
						font-size: 1.8rem;
						margin-bottom: 0px;
					}
				}

				.cardPera {
					padding: 1rem 0px 1.5rem 0px;

					p {
						text-align: center;
						font-family: @secondary-font;
						font-weight: 300;
						color: @text-input-color;
						font-size: 2rem;

						@media screen and (max-width: 1600px) {
							font-size: 1.8rem;
						}

						@media screen and (max-width: 1200px) {
							font-size: 1.5rem;
						}

						@media screen and (max-width: 1024px) {
							font-size: 1.3rem;
						}
					}
				}

				@media screen and (max-width: 1024px) {
					padding: 2.5rem;
				}

				@media screen and(max-width:768px) {
					padding: 1.5rem;
				}
			}
			// &:hover {
			// 	transform: scale(0.99);

			// 	.readMoreLink {
			// 		color: @primary-color;

			// 		span {
			// 			svg {
			// 				path {
			// 					fill: @primary-color;
			// 				}
			// 			}
			// 		}

			// 		&::after {
			// 			background-color: @primary-color;
			// 		}
			// 	}
			// }
		}

		@media screen and(max-width:1024px) {
			.ant-col-8 {
				max-width: 50%;
				flex: 0 0 50%;
			}
		}

		@media screen and(max-width:600px) {
			.ant-col-8 {
				max-width: 100%;
				flex: 0 0 100%;
			}
		}

		@media screen and (max-width: 1024px) {
			.ant-col-6 {
				max-width: 50%;
				flex: 0 0 50%;
			}
		}

		@media screen and (max-width: 600px) {
			.ant-col-6 {
				max-width: 100%;
				flex: 0 0 100%;
			}
		}
	}

	.noneCard {
		@media screen and (max-width: 1024px) {
			.ant-col-12 {
				flex: 0 0 50%;
				max-width: 50%;
			}
		}
		@media screen and (max-width: 600px) {
			.ant-col-12 {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
	}
}

.serviceGuide {
	padding: 10rem 0;
	background: @bg-grey-color;
	box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
	@media screen and (max-width:600px) {
		padding: 4rem 0 6rem;
	}
	.trackContent {
		display: flex;
		// align-items: center;
		justify-content: center;
		flex-direction: column;
		.heading {
			h1 {
				@media screen and (max-width:1600px) {
					font-size: 5.3rem;
				}
				@media screen and (max-width:1400px) {
					font-size: 5rem;
				}
				@media screen and (max-width:1200px) {
					font-size: 4.2rem;
				}
				@media screen and (max-width:991px) {
					font-size: 3.5rem;
				}
			}
		}
	}

	img {
		width: 100%;
		height: auto;
	}
}

.BannedGuide {
	background: #f4f7fc;
	padding: 10rem 0;
	.trackContent {
		.headingContent {
			h2 br {
				display: none;
			}
		}
	}
	@media screen and (max-width:767px) {
		padding: 5rem 0;
	}
	.centerCard {
		justify-content: center;
		margin-top: 5.5rem;
		@media screen and(max-width:1024px) {
			margin-top: 3rem;
		}
		.cardTitle {
			color: #000;
			font-family: @primary-font;
			font-size: 2.2rem;
			font-weight: 600;
		}
		.cardList {
			@media screen and(max-width:1200px) {
				padding-left: 1.2rem;
			}
			li {
				list-style: disc;
				padding: 1rem 0px;
				color: #000;

				font-family: @forth-banner-font;
				font-size: 1.9rem;

				font-weight: 400;

				@media screen and(max-width:1200px) {
					font-size: 1.6rem;
				}

				@media screen and(max-width:1024px) {
					font-size: 1.4rem;
				}
			}
		}

		@media screen and(max-width:991px) {
			.ant-col-8 {
				max-width: 50%;
				flex: 0 0 50%;
			}
		}

		@media screen and(max-width:768px) {
			.ant-col-8 {
				max-width: 100%;
				flex: 0 0 100%;
			}
		}

		.ant-card {
			// height: 42rem;
			&.ant-card-bordered {
				height: 100%;
				ul {
					margin: 0;
					padding: 0 0 0 2rem;
					list-style: disc;
					li {
						font-size: 2.2rem;
						font-weight: 300;
						list-style: disc;
						font-family: @secondary-font;
						padding: 5px 0;
					}
				}
			}
			// @media screen and(max-width:1024px) {
			// 	height: 35rem;
			// }

			@media screen and(max-width:768px) {
				margin-bottom: 1.5rem;
				&.ant-card-bordered {
					height: auto;
				}
			}
		}
	}
}
.fuleCharge {
	padding: 10rem 0;
	.alignCenter {
		justify-content: flex-start;
	}
	@media screen and (max-width:767px) {
		padding: 5rem 0;
	}
}
.WeightGuide {
	background-color: #f4f7fc;
	padding: 10rem 0;
	.container {
		.ant-row {
			align-items: center;
		}
	}
	.weightCalc {
		.weightInput {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: flex-end;
			width: 100%;
			.calBox {
				display: flex;
				align-items: center;
				padding-bottom: 2rem;
				width: 25%;
				@media screen and (max-width: 600px) {
					width: 50%;
				}
				&.calResult {
					display: flex;
					align-items: center;

					width: 35%;
					min-width: 182px;
					@media screen and (max-width: 600px) {
						width: 50%;
						min-width: 75%;
					}
				}
			}
			.ant-col {
				padding-left: 0;
				padding-right: 0;
			}
			.ant-form-item {
				margin-bottom: 0;
				width: 100%;
				//padding-bottom: 1.2rem;
				&.resultInput {
					padding-top: 0rem;
					.ant-input-number {
						width: 100%;
					}
				}
				.ant-input-number {
					max-height: 6.7rem;
					min-height: 4.7rem;
					border-radius: 1rem;
					width: 100%;

					.ant-input-number-input {
						padding: 16px 11px;
						height: auto;
						max-height: 6.7rem;
						min-height: 4.7rem;
					}
					.ant-input-number-handler-wrap {
						display: none;
						.ant-input-number-input {
							padding: 16px 11px;
							height: auto;
							max-height: 6.7rem;
							min-height: 4.7rem;
						}
						.ant-input {
							height: 3.8rem;
						}
					}

					@media screen and (max-width: 800px) {
						margin: 0.5rem 0px;
					}
				}
			}
		}
		.calculatorSign {
			padding-left: 1rem;
			padding-right: 1rem;
			@media screen and (max-width: 600px) {
				width: 42px;
			}
		}
		.weightResult {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			padding-top: 2rem;
			margin-right: 0rem;

			.ant-form-item {
				margin-bottom: 0;
				.ant-col {
					padding-left: 0;
					padding-right: 0;
				}
				.ant-input-number {
					width: 18rem;
					max-width: 100%;
					max-height: 6.7rem;
					min-height: 4.7rem;
					border-radius: 1rem;

					.ant-input-number-input {
						padding: 12px 11px;
						height: auto;
						max-height: 6.7rem;
						min-height: 4.7rem;
					}
					.ant-input-number-handler-wrap {
						display: none;
					}
				}
			}
		}
	}

	.methodHeading {
		color: @primary-color;
		font-family: @primary-font;
		font-size: 2.7rem;
		margin-top: 2rem;
		font-weight: 600;
		letter-spacing: 0.11em;
		text-transform: uppercase;

		@media screen and (max-width: 1200px) {
			font-size: 2.4rem;
		}

		@media screen and (max-width: 1024px) {
			font-size: 2.2rem;
		}

		@media screen and (max-width: 991px) {
			font-size: 1.9rem;
		}

		@media screen and (max-width: 480px) {
			font-size: 1.6rem;
			letter-spacing: 0.01em;
			margin-bottom: 5px;
		}
	}

	.valueHeading {
		font-size: 2.1rem;
		font-family: @secondary-font;
		color: @secondary-color;
		//font-weight: 600;
		margin-bottom: 0rem;
		p {
			margin-top: 0;
		}
		@media screen and (max-width: 1024px) {
			font-size: 1.8rem;
		}

		@media screen and (max-width: 991px) {
			font-size: 1.5rem;
		}

		@media screen and (max-width: 480px) {
			font-size: 1.2rem;
			margin-bottom: 0.5rem;
		}
	}

	.weightImg {
		margin-top: 0rem;
		// margin-bottom: 2rem;
		@media screen and (max-width:991px) {
			padding-bottom: 2rem;
		}
		img {
			height: auto;
			width: 100%;
		}
	}
}

.headingContent {
	color: @secondary-color;
	font-family: @mild-font;
	font-size: 5rem;
	font-weight: 500;

	text-transform: capitalize;

	@media screen and (max-width: 1200px) {
		font-size: 4.2rem;
	}

	@media screen and (max-width: 1024px) {

		font-size: 4rem;
	}

	@media screen and (max-width: 991px) {
		//padding-top: 3rem;
		font-size: 3.5rem;
	}

	@media screen and (max-width: 480px) {
		font-size: 2.6rem;
	}
}