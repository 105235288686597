.aboutUsContainer {
	.aboutUsBg {
		background: #f9f9f9;
		box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
		padding: 10rem 0;
		@media screen and (max-width: 767px) {
			padding: 5rem 0px 7rem;
		}
		.aboutBanner {
			display: flex;
			align-items: center; /*justify-content: center;*/
		}
	}
	.messageComponent {
		background-color: @banner-bg-color;
		padding: 6.6rem 0px;
		box-shadow: 0 0 1rem 1rem #f4f7fc;
		@media screen and (max-width: 767px) {
			padding: 5rem 0px;
		}
	}
	.GrowthComponent {
		background-color: transparent;
		box-shadow: none;
		.title {
			padding-bottom: 4rem;
		}
		.growthImg {
			img {
				width: auto;
				max-width: 100%;
			}
		}
	}
	.accreditationContainer {
		padding: 10rem 0;
		@media screen and (max-width: 767px) {
			padding: 5rem 0px 9rem;
		}
	}
	.partnerSection {
		padding: 10rem 0;
		background-color: @banner-bg-color;
		box-shadow: 0 0 1rem 1rem #f4f7fc;
		@media screen and (max-width: 767px) {
			padding: 5rem 0px;
		}
	}
	.aboutBanner {
		img {
			width: 100%;
			height: auto;
		}
	}

	.aboutContent {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.aboutUsSlider {
		margin-top: 5rem;

		.aboutUsContent {
			margin: 0;
			height: 100%;
             .ant-image{
				.ant-image-mask{
					.ant-image-mask-info{
						font-size: 18px;
					}
				}
			 }
			@media screen and (max-width: 600px) {
				margin: 0;
			}
		}

		.ant-image {
			border: 1rem solid #d9d9d9;
			margin-right: 1rem;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			border-radius: 0.4rem;
			@media screen and(max-width:480px) {
				width: 100%;
				margin-right: 0rem;
			}
		}

		.slick-slider {
			.slick-list {
				.slick-track {
					display: flex;
					height: 535px;
					.slick-slide {
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						> div {
							width: 100%;
							height: 100%;
						}
					}

					@media screen and(max-width:400px) {
						height: 380px;
					}
				}
			}

			.slick-prev {
				left: 1rem;
				top: auto;
				bottom: -5rem;

				&::before {
					content: url("../../../images/rightArrow.svg");
					position: absolute;
				}

				&:hover {
					svg {
						path {
							fill: #000;
						}
					}
				}

				@media screen and(max-width:991px) {
					display: none !important;
				}
			}

			.slick-next {
				right: 6.8rem;
				top: auto;
				bottom: -5rem;

				&::before {
					content: url("../../../images/leftArrow.svg");
					position: absolute;
				}

				@media screen and(max-width:991px) {
					display: none !important;
				}
			}
		}

		.slick-dots {
			// left: -2%;
			position: relative;
			max-width: 25rem;
			margin: 0 auto;
			line-height: 0.5;
			bottom: -4rem;

			li {
				button {
					&::before {
						font-size: 0;
						line-height: 0;
						display: block;
						width: 2rem;
						height: 0.3rem;
						cursor: pointer;
						color: transparent;
						border-radius: 1rem;
						outline: none;
						background: @primary-color;
						margin-right: 2.8rem;
						margin-top: 1.1rem;
					}
				}
			}

			&::after {
				position: absolute;
				content: "";
				height: 1.1rem;
				width: 1.1rem;
				border: 1px solid @primary-color;
				border-radius: 50%;
				top: 37%;
				right: -1rem;
				@media screen and(max-width:600px) {
					display: none;
				}
			}

			&::before {
				position: absolute;
				content: "";
				height: 1.1rem;
				width: 1.1rem;
				border: 1px solid @primary-color;
				border-radius: 50%;
				top: 37%;
				left: -1rem;
				@media screen and(max-width:600px) {
					display: none;
				}
			}
		}
	}
}

//  partner section
.partnerSection {
	.showInPC {
		margin-top: 4rem;
		.partnerTabs {
			padding: 0px 0px;
			height: 100%;
			border-radius: 20px 20px 0px 0px;
			box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
			background: #fff;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 0;
			.partnerTab {
				width: 100%;
				position: relative;
				cursor: pointer;
				&::before {
					position: absolute;
					content: "";
					width: 0;
					height: 0;
					border-left: 10px solid transparent;
					border-right: 10px solid transparent;
					border-top: 14px solid red;
					bottom: -15px;
					right: 0;
					left: 0;
					display: none;
					margin: 0 auto;
				}
				&:after {
					position: absolute;
					content: "";
					height: 70%;
					width: 2px;
					background-color: rgba(218, 218, 218, 0.5);
					top: 25px;
					right: 0;
				}
				&:nth-child(1) {
					border-bottom: 3px solid @primary-color;
					.partnerTabTitle {
						.partnerIconBg {
							background-color: @primary-color;
						}
					}
					&.active {
						&::before {
							display: block;
							border-top: 14px solid @primary-color;
						}
					}
				}
				&:nth-child(2) {
					border-bottom: 3px solid #71a8d0;
					.partnerTabTitle {
						.partnerIconBg {
							background-color: #71a8d0;
						}
					}
					&.active {
						&::before {
							display: block;
							border-top: 14px solid #71a8d0;
						}
					}
				}
				&:nth-child(3) {
					border-bottom: 3px solid #f59016;
					.partnerTabTitle {
						.partnerIconBg {
							background-color: #f59016;
						}
					}
					&.active {
						&::before {
							display: block;
							border-top: 14px solid #f59016;
						}
					}
				}
				&:nth-child(4) {
					border-bottom: 3px solid #e85454;
					.partnerTabTitle {
						.partnerIconBg {
							background-color: #e85454;
						}
					}
					&.active {
						&::before {
							display: block;
							border-top: 14px solid #e85454;
						}
					}
				}
				&:nth-child(5) {
					border-bottom: 3px solid #33d1ab;
					.partnerTabTitle {
						.partnerIconBg {
							background-color: #33d1ab;
						}
					}
					&.active {
						&::before {
							display: block;
							border-top: 14px solid #33d1ab;
						}
					}
				}
			}

			.partnerTabTitle {
				border: none;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				padding: 24px 57px;
				@media screen and(max-width:1600px) {
					padding: 24px;
				}
				.cardWrap {
					font-family: @forth-banner-font;
					font-size: 2rem;
					margin-top: 1rem;
					color: rgba(0, 0, 0, 0.6);

					@media screen and(max-width:1600px) {
						font-size: 1.6rem;
					}
					@media screen and(max-width:1200px) {
						font-size: 1.4rem;
					}
				}
				.partnerIconBg {
					text-align: center;
					border-radius: 50%;
					height: 86px;
					padding: 10px;
					width: 86px;
					display: flex;
					align-items: center;
					justify-content: center;
					@media screen and(max-width:1500px) {
						height: 70px;
						width: 70px;
						img {
							height: 40px;
							width: 40px;
						}
					}
					@media screen and(max-width:1200px) {
						height: 50px;
						width: 50px;
						img {
							height: 28px;
							width: 28px;
						}
					}
				}
			}

			@media screen and (max-width: 991px) {
				display: none;
			}
		}

		@media screen and(max-width:991px) {
			display: none;
		}
	}
	.tabRow {
		width: 100%;
		border-radius: 0px 0px 20px 20px;
		background: #f4f4f4;
		padding: 20px;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
		grid-gap: 15px;
		.clientCard {
			border-radius: 20px;
			background-color: #fff;
			width: 100%;
			height: 100%;
			max-height: 130px;
			padding: 15px 18px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			@media screen and(max-width:480px) {
				border-radius: 15px;
				max-height: 68px;
				padding: 10px;
			}
		}
		img {
			display: block;
			width: auto;
			height: 100%;
			max-height: 130px;
			object-fit: cover;
		}
		@media screen and(max-width:580px) {
			grid-template-columns: repeat(2, 1fr);
			padding: 10px;
			grid-gap: 10px;
		}
		@media screen and(max-width:400px) {
			grid-template-columns: repeat(1, 1fr);
		}
		@media screen and(max-width:300px) {
			grid-gap: 5px;
		}
	}
}
.showInMob {
	margin-top: 1.5rem;
	display: none;
	.partnerAccrodion {
		.ant-collapse {
			border-radius: 20px;
			box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
			.ant-collapse-item {
				.partnerIconBg {
					text-align: center;
					border-radius: 50%;
					height: 55px;
					padding: 10px;
					width: 55px;
					display: flex;
					align-items: center;
					justify-content: center;
					img {
						height: 35px;
						width: 35px;
					}
				}
				.ant-collapse-header-text {
					.cardWrap {
						font-family: @forth-banner-font;
						font-size: 1.8rem;
						margin-left: 1.2rem;
						color: rgba(0, 0, 0, 0.6);

						@media screen and(max-width:480px) {
							font-size: 1.3rem;
						}
					}
				}
				&:nth-child(1) {
					&.ant-collapse-item-active {
						.ant-collapse-header {
							border-bottom: 3px solid @primary-color;
						}
					}
					.ant-collapse-header-text {
						display: flex;
						align-items: center;
						.partnerIconBg {
							background-color: @primary-color;
						}
					}
				}
				&:nth-child(2) {
					&.ant-collapse-item-active {
						.ant-collapse-header {
							border-bottom: 3px solid #71a8d0;
						}
					}
					.ant-collapse-header-text {
						display: flex;
						align-items: center;
						.partnerIconBg {
							background-color: #71a8d0;
						}
					}
				}
				&:nth-child(3) {
					&.ant-collapse-item-active {
						.ant-collapse-header {
							border-bottom: 3px solid #f59016;
						}
					}
					.ant-collapse-header-text {
						display: flex;
						align-items: center;
						.partnerIconBg {
							background-color: #f59016;
						}
					}
				}
				&:nth-child(4) {
					&.ant-collapse-item-active {
						.ant-collapse-header {
							border-bottom: 3px solid #e85454;
						}
					}
					.ant-collapse-header-text {
						display: flex;
						align-items: center;
						.partnerIconBg {
							background-color: #e85454;
						}
					}
				}
				&:nth-child(5) {
					&.ant-collapse-item-active {
						.ant-collapse-header {
							border-bottom: 3px solid #33d1ab;
						}
					}
					.ant-collapse-header-text {
						display: flex;
						align-items: center;
						.partnerIconBg {
							background-color: #33d1ab;
						}
					}
				}
			}
			.ant-collapse-content {
				.ant-card {
					.ant-card-body {
						img {
							height: 118px;
							width: 118px;
						}
					}
				}
			}
		}
	}
	@media screen and(max-width:991px) {
		display: block;
	}
}
