.main__page__content{
	.GrowthComponent {
		background-color: @banner-bg-color;
		box-shadow: 0 0 1rem 1rem #f4f7fc;
		padding: 10rem 0;
		@media screen and (max-width:767px) {
			padding: 5rem 0;
		}
		.GrowthContent{
			flex-direction: column;
			align-items: flex-start;
			height: 100%;
			justify-content: center;
			.peraFonts {
				.Growthpera {
					padding: 2rem 0px;
					br{display: none;}
				
				p {
					font-size: 2rem;
					font-family: @secondary-font;
					font-weight: 300;
					color: @secondary-color;
					margin-bottom: 0px;
					margin-top: 0px;
					padding-top: 9px;
					padding-bottom: 9px;
					line-height: 3.5rem;
					
					@media screen and (max-width: 1024px) {
						font-size: 1.8rem;
						line-height: 2.8rem;
						//margin-bottom: 0px;
					}

				}
			}
				@media screen and (max-width: 991px) {
					margin-bottom: 1rem;
					
				}
			}
			@media screen and (max-width:991px) {
				.headingContent{
					padding-top: 0;
				}
			}
		}
		@media screen and (max-width:767px) {
			.growthImg{padding-bottom: 3rem;}
		}
	}	
}

.GrowthComponent {
	// background-color: @banner-bg-color;
	// box-shadow: 0 0 1rem 1rem #f4f7fc;
	.GrowthContent {
		.peraFonts {
			.Growthpera {
				padding: 2rem 0px;
			}
			p {
				font-size: 2.4rem;
				font-family: @secondary-font;
				font-weight: 300;
				color: @secondary-color;
				margin-bottom: 0px;
				margin-top: 0px;
				padding-top: 18px;
				padding-bottom: 18px;
				line-height: 4.2rem;
				@media screen and (max-width: 1400px) {
					font-size: 2rem;
					line-height: 3.8rem;
				}

				@media screen and (max-width: 1024px) {
					font-size: 1.8rem;
					line-height: 3rem;
				}
				@media screen and (max-width:800px) {
					line-height: 3rem;
				}
			}
			@media screen and (max-width: 991px) {
				margin-bottom: 1rem;
			}
		}
		@media screen and (max-width: 1024px) {
			margin-left: 2rem;
		}
		@media screen and (max-width: 800px) {
			margin-left: 0rem;
		}
	}
	.afterLine {
		top: 1.2rem;
		left: 45.5rem;
		@media screen and (max-width: 1200px) {
			top: 1.2rem;
			left: 43.8rem;
		}
		@media screen and (max-width: 1024px) {
			top: 0.5rem;
			left: 36.6rem;
		}
		@media screen and(max-width:600px) {
			left: 32.1rem;
		}
		@media screen and (max-width: 480px) {
			top: 0.5rem;
			left: 27.1rem;
		}
		@media screen and (max-width: 320px) {
			top: 2.1rem;
			left: 8rem;
		}
	}
	@media screen and(max-width:480px) {
		.subHeading {
			.headingSpan {
				font-size: 0.9rem;
				&::before {
					width: 2rem;
					right: -2.8rem;
					top: 0.5rem;
				}
			}
		}
	}
	.growthImg {
		svg,
		img {
			height: auto;
			width: 100%;
		}
	}
	.ant-col-11 {
		@media screen and (max-width: 1024px) {
			flex: 0 0 50%;
			max-width: 50%;
		}
		@media screen and (max-width: 800px) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	.ant-col-12 {
		@media screen and (max-width: 1024px) {
			flex: 0 0 50%;
			max-width: 50%;
		}
		@media screen and (max-width: 800px) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	.headingContent {
		@media screen and (max-width: 480px) {
			padding-top: 2rem;
		}
		@media screen and (max-width: 350px) {
			padding-top: 0px;
		}
	}
	.subHeading {
		.subTitle {
			b {
				position: relative;
				text-transform: uppercase;
				&::after {
					position: absolute;
					background-color: #000;
					height: 1px;

					content: "";
					top: 1.5rem;
					right: -8rem;
					width: 7rem;
					// @media screen and (min-width: 1125px) {
					// 	left: 12.5rem;
					// }
					@media screen and (max-width: 1024px) {
						width: 5rem;
						right: -6rem;
					}
					@media screen and (max-width:480px) {
					top:10px;
					}
				}
			}

			@media screen and (max-width: 1300px) {
				width: 92%;
				display: flex;
				flex-wrap: wrap;
			}

			@media screen and (max-width: 600px) {
				font-size: 2rem;
			}

			// @media screen and (max-width: 480px) {
			// 	font-size: 1.3rem;
			// }
		}
		@media screen and (max-width: 1300px) {
			font-size: 2.3rem;
		}
	}

	.afterLine {
		@media screen and (max-width: 480px) {
			top: 2.3rem;
			left: 80.5rem;
		}
	}
}
