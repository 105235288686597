.bgCourierContainer {
	position: relative;
	// .bottomLinear();
	background-color: @banner-bg-color;
	box-shadow: 0 0 1rem 1rem #f4f7fc;
padding: 13rem 0;
@media screen and (max-width:767px) {
	padding: 5rem 0;
}
	.BestCourierContainer {
		// @media screen and (max-width:800px) {
		//     margin-top: 200px;
		// }

		.ContentContainer {
			margin-left: 10rem;

			// @media screen and (max-width:800px) {
			//     margin-top: 22rem;
			// }

			.downloadHeading {
				color: @primary-color;
				font-size: 3rem;
				margin-bottom: 0;
				text-transform: uppercase;
				font-family: @secondary-font;
				font-weight: 500;
				@media screen and (max-width: 1400px) {
					font-size: 2.8rem;
				}
				@media screen and (max-width: 991px) {
					font-size: 2rem;
				}
			}

			.appBtn { padding:0 0 30px 0;
				// .appleLink {
				// 	// margin-left: 3rem;
				// 	// @media screen and(max-width:1200px) {
				// 	// 	margin-left: 2rem;
				// 	// }
				// 	// @media screen and(max-width:800px) {
				// 	// 	margin-left: 1rem;
				// 	// }
				// }
				@media screen and(max-width:600px) {
					margin: 2rem 0px;
				}

				// a {
				// 	@media screen and(max-width:1200px) {
				// 		width: 50%;
				// 	}
				// 	@media screen and(max-width:800px) {
				// 		width: 100%;
				// 	}

				// 	img {
				// 		@media screen and(max-width:1200px) {
				// 			width: 100%;
				// 		}
				// 	}
				// }

				@media screen and(max-width:1200px) {
					display: flex;
				}
			}

			.pera {
				p {
					@media screen and(max-width:1200px) {
						font-size: 1.8rem;
					}
					@media screen and(max-width:1024px) {
						font-size: 1.5rem;
					}
					@media screen and(max-width:480px) {
						font-size: 1.2rem;
					}
				}
			}
			@media screen and (max-width: 1200px) {
				margin-left: 7rem;
			}
			@media screen and (max-width: 800px) {
				margin-left: 0px;
			}
		}

		.slick-dots {
			li {
				button {
					&::before {
						font-size: 0;
						line-height: 0;
						display: block;
						width: 2.5rem;
						height: 0.3rem;
						cursor: pointer;
						color: transparent;
						border-radius: 1rem;
						outline: none;
						background: @primary-color;
						margin-right: 2.8rem;
						margin-top: 1rem;
					}
				}
			}
		}

		.mainSliders {
			position: relative;
			z-index: 9;
			height: 100%;

			&::before {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				margin: 0 auto;
				top: 50%;
				transform: translateY(-50%);
				width: 100%;
				height: 60%;
				// max-height: 60%;
				background-color: #ff86cd;
				opacity: 0.6;
				border-radius: 7rem;
				z-index: -9;

				@media screen and (max-width: 800px) {
					height: 30rem;
				}
			}

			&::after {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				margin: 0 auto;
				top: 50%;
				transform: translateY(-50%);
				width: 80%;
				max-width: 80%;
				// height: 450px;
				height: 80%;
				background-color: #ed008c;
				opacity: 0.5;
				border-radius: 7rem;
				z-index: -8;
			}

			.slick-slide {
				img {
					margin: 0 auto;
				}
			}

			.mobileContainer {
				// position: absolute;
				// top: 50%;
				// left: 50%;
				// transform: translate(-50%, -50%);
				
				// background-position: top;
				// background-repeat: no-repeat;
				// background-size: contain;
				// height: 100%;
				// overflow: hidden;
				// position: relative;
				background-color: transparent;
				height: 100%;
				overflow: hidden;
				margin: 0 auto;
				padding: 0px 2rem;
				box-sizing: border-box;
				max-width: 29rem;
				position: relative;

				.downloadContent {
					max-width: 25rem !important;
					margin: 0 auto;
					display: block !important;

					img {
						max-height: 66.3rem;
						padding-top: 8px;
						padding-bottom: 8px;
						width: 100%;
						object-fit: cover;
						margin: 0 auto;
					}
				}
				&::before {
					content: "";
					height: 100%;
					width: 100%;
					display: block;
					z-index: 1;
					background-image: url("../../../images/mobileContainer.png");
				background-position: center center;
				background-repeat: no-repeat;
				background-size: contain;
					position: absolute;
					left: 0;
					right: 0;
					top: 50%;
					bottom: 0;
					margin: 0 auto;
					transform: translateY(-50%);
				}
			}

			.slick-slider {
				margin: 0 auto;
				max-width: 100%;
			}
		}

		.col-100 {
			@media screen and(max-width:1024px) {
				flex: 0 0 50%;
				max-width: 50%;
			}
			@media screen and(max-width:800px) {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		.boxImg {
			position: absolute;
			right: 8rem;
		}

		.ant-row {
			align-items: center;
		}
	}
	@media screen and(max-width:1200px) {
		.col-100 {
			flex: 0 0 50%;
			max-width: 50%;
		}
	}
}
