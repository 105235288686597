.ant-modal-centered .ant-modal.publicNot-content {
  // vertical-align: bottom;
  max-width: 85% !important;
  height: auto;
  // min-height: 200px;
  width: auto !important;
  margin-bottom: 10px;


  .ant-modal-header .ant-modal-title{
    color: @primary-color;
    text-transform: uppercase;
    margin-bottom: 0;
    padding:8px 0;
    font-size: 3rem;
    text-align: center;
    @media screen and (max-width:767px) {
      font-size: 2.2rem;
    }
  }
  .ant-modal-body {
    text-align: center;
    max-height: 620px;
    overflow: hidden;
    overflow-y: auto;
  .modalImg {
      height: 100%;
      width: 100%;
      img{
        height: auto;
        max-height: 550px;
        // width: 50%;
        max-width: 100%;
      }
    }
  }

  p {
    font-size: 1.8rem;
  }
  @media screen and (max-width:767px) {
    width: auto !important;
    max-width: inherit !important;
    padding: 0 10px;
  }
}