.hero__section {
	display: flex;
	align-items: center;
	background-color: @banner-bg-color;
	// height:82vh;
	padding: 10rem 0px 20rem;
	box-shadow: 0 0 1rem 1rem #f4f7fc;
	// .bottomLinear();
	// height: calc(~"100vh - @{layout-header-height}");
	@media screen and (max-width:767px) {
		padding: 5rem 0 10rem;
	}
	.content_wrapper {
		align-items: center;
		position: relative;
		z-index: 2;
		.section__big__title {
			font-family: @forth-banner-font;
			font-size: 8rem;
			font-weight: 800;
			color: @secondary-color;
			margin-bottom: 0px;

			.andTitle {
				font-family: @forth-banner-font;

				font-weight: 300;
				color: @secondary-color;
			}

			.sectionSubTitle {
				font-family: @mild-font;

				font-weight: 700;
				color: @secondary-color;
				margin-bottom: 0px;
			}
			@media screen and (max-width: 1400px) {
				font-size: 5.5rem;
			}
			@media screen and (max-width: 1200px) {
				font-size: 5rem;
			}
			@media screen and (max-width: 1024px) {
				font-size: 4rem;
			}
			@media screen and (max-width: 499px) {
				font-size: 3.2rem;
			}
			@media screen and (max-width: 399px) {
				font-size: 2.7rem;
			}
		}

		img {
			max-width: 100%;
			max-height: 100%;
		}
		.commanBtn {
			margin-top: 1.5rem;
			cursor: pointer;
		}
	}

	.container {
		position: relative;

		.logisticHeading {
			color: @primary-color;
			font-size: 3rem;
			margin-bottom: 0;
			text-transform: uppercase;
			font-family: @secondary-font;
			font-weight: 500;
			@media screen and (max-width: 1400px) {
				font-size: 2.8rem;
			}
			@media screen and (max-width: 991px) {
				font-size: 2rem;
			}
	
		}
		.heading {
			h1 {
				font-size: 7rem;
				margin-bottom: 0.3rem;
				color: @secondary-color;
				font-family: @forth-banner-font;
				font-weight: 300;
				strong {
					font-weight: 800;
				}
				b {
					font-family: @mild-font;
					color: @secondary-color;
					font-weight: 700;
				}
				@media screen and (max-width: 1600px) {
					font-size: 6rem;
				}
				@media screen and (max-width: 1440px) {
					font-size: 5.2rem;
				}
				@media screen and (max-width: 1200px) {
					font-size: 4.2rem;
				}
				@media screen and (max-width: 1024px) {
					font-size: 4rem;
				}
				@media screen and (max-width: 991px) {
					font-size: 3.3rem;
				}
				@media screen and (max-width:400px) {
					font-size: 2.8rem;
				}
			}
		}
		.bannerPera {
			max-width: 100%;
			width: 780px;
			line-height: 1.6;
			p {
				@media screen and (max-width: 1400px) {
					font-size: 2.1rem;
				}
				// @media screen and (max-width: 1200px) {
				// 	font-size: 1.8rem;
				// }
				// @media screen and (max-width: 480px) {
				// 	font-size: 1.4rem;
				// }
			}
		}

		.bannerBtn {
			button {
				margin-top: 1rem;
			}
		}

		.bLbannerImg {
			position: absolute;
			bottom: -23rem;
			left: 3rem;
			@media screen and (max-width: 1200px) {
				width: 27rem;
				height: 27rem;
			}
			
			@media screen and (max-width: 768px) {
				width: 18rem;
				height: 18rem;
				bottom: -15rem;
			}
			@media screen and (max-width: 600px) {
				//visibility: hidden;
				bottom: -17rem;
			}
			img {
				@media screen and (max-width: 1200px) {
					width: 100%;
					height: 100%;
				}
			}
		}

		.centerBottomImg {
			position: absolute;
			left: 37%;
			// height: 200px;
			// width: 188px;
			bottom: -7%;

			img {
				width: 10rem;
				height: 100%;
				@media screen and (max-width: 991px) {
					width: 8.2rem;
				}
			}
			@media screen and (max-width: 991px) {
				bottom: 0;
			}
		}
	}
	@media screen and (max-width: 800px) {
		margin-bottom: 0px;
		padding-bottom: 10rem;
	}
}
.bannerModel {
	.anticon-close {
		font-size: 2.4rem;
		color: #000;
	}
	.ant-modal-body {
		h1 {
			padding: 3rem;
			color: @primary-color;
			font-size: 4rem;
			margin-bottom: 0;
			text-align: center;
		}
	}
	.ant-modal-footer {
		display: none;
	}
}
