.messageComponent {
	padding: 5rem 0px 10rem 0px;
	
	@media screen and (max-width:767px) {
		
		padding-bottom: 8rem;
	}
	.container {
		.messageContainer {
			.subHeading {
				.headingSpan {
					left: 0 !important;
				}
			}
			.centerBottomImg {
				position: absolute;
				height: 10rem;
				width: 10rem;
				right: 1.9rem;
				img {
					height: 100%;
					width: 100%;
				}
			}
		}

		.peraFonts {
			padding: 0px 5rem 0px 0px;

			@media screen and (max-width: 800px) {
				padding: 0px 0px 0px 0px;
			}
			p {
				@media screen and (max-width: 480px) {
					margin-bottom: 1rem !important;
				}
			}
		}

		.messageSlider {
			.messageCard {
				background-color: @white;
				max-width: 100%;
				position: relative;

				.messageContent {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					position: inherit;
					padding: 1.5rem;
					z-index: 99;
					.name {
						font-size: 2.2rem;
						@media screen and (max-width:800px) {
							
							font-size: 1.8rem;
						}
						@media screen and (max-width:480px) {
							
							font-size: 1.4rem;
						}
					}
					.designation {
						font-size: 1.6rem;
						font-style: italic;
						padding-bottom: 1.6rem;
						@media screen and (max-width:800px) {
							
							font-size: 1.4rem;
						}
						@media screen and (max-width:480px) {
							
							font-size: 1.2rem;
						}
					}
					.sliderPera {
						text-align: center;
						line-height: 1.4;

						p {
							font-size: 1.8rem;
							margin-top: 0rem;
							padding-top: 1em;
							@media screen and (max-width:1200px) {
								margin-top:1rem;
								font-size: 1.6rem;
								line-height: 1.4;
							}
							@media screen and (max-width:480px) {
								margin-top:0rem;
								font-size: 1.6rem;
							
							}
						}
					}
			

					.bootomImg {
						height: 12.4rem;
						width: 12rem;
						box-shadow: 0px 0px 3rem 0px rgb(0 0 0 / 6%);
						border-radius: 50%;

						img {
							height: 100%;
							width: 100%;
							border-radius: 50%;
						}
						@media screen and (max-width: 1200px) {
							height: 9rem;
							width: 9rem;
						}
						@media screen and (max-width: 480px) {
							height: 7rem;
							width: 7rem;
						}
					}
					@media screen and(max-width:768px) {
						padding: 0.5rem 0.5rem 0.5rem 0.5rem;
				}
				@media screen and(max-width:600px) {
					padding:3rem 1.5rem;
			}
				}

				&::before {
					content: "";
					background-image: url("../../../images/topMsgImg.svg");
					right: 0;
					background-size: contain;
					/* padding-bottom: 24%; */
					width: 100%;
					background-position: right top;
					/* left: 160px; */
					height: 6dvw;
					background-repeat: no-repeat;
					position: absolute;

					// @media screen and(max-width:1400px) {
					//    left:130px;
					//    }
					//    @media screen and(max-width:1200px) {
					//     left:165px;
					//    }
					//    @media screen and(max-width:800px) {
					//     left:120px;
					//    }
					//    @media screen and(max-width:749px) {
					//     left:11rem;
					//    }
					   @media screen and(max-width:768px) {
						height: 14dvh;
					   }
				}

				&::after {
					position: absolute;
					content: "";
					background-image: url("../../../images/bottomMsgImg.svg");
					background-size: contain;
					padding-bottom: 51%;
					width: 100%;
					bottom: -3px;
					@media screen and(max-width:768px) {
						bottom: -5px;
					}
				}

				@media screen and(max-width:1024px) {
					//width: 46rem;
				min-height: 46rem;
					// height: auto;
					
				}
			}

			.slick-dots {
				// left: -25%;
				left: 0rem;
				right: 0;
				margin: 0 auto;
				max-width: 25rem;
				position: relative;
				bottom: -14px;
				li {
					button {
						&::before {
							font-size: 0;
							line-height: 0;
							display: block;
							width: 2rem;
							height: 0.3rem;
							cursor: pointer;
							color: transparent;
							border-radius: 1rem;
							outline: none;
							background: #cccccc;
							margin-right: 2.8rem;
							margin-top: 1.1rem;
						}
					}
					&.slick-active {
						button {
							&::before {
								background-color: @primary-color;
							}
						}
					}
				}

				&::after {
					position: absolute;
					content: "";
					height: 1.1rem;
					width: 1.1rem;
					border: 1px solid @primary-color;
					border-radius: 50%;
					top: 50%;
					right: 20px;
					// @media screen and(max-width:1400px) {
					//     right: 42%;
					// }
					@media screen and(max-width:600px) {
					display: none;
					}
					
				}

				&::before {
					position: absolute;
					content: "";
					height: 1.1rem;
					width: 1.1rem;
					border: 1px solid @primary-color;
					border-radius: 50%;
					top: 50%;
					left: 20px;
					// @media screen and(max-width:1400px) {
					//     left: 42%;
					// }
					@media screen and(max-width:600px) {
						display: none;
						}
				
				}
				@media screen and(max-width:1200px) {
					left: 0;
					// max-width:100%;
					margin: 0 auto;
				}
				@media screen and(max-width:1024px) {
					left: 0;
				}
				@media screen and(max-width:480px) {
					bottom: 0;
				}
			}

			.slick-list {
				padding: 3rem 0 0;
				@media screen and(max-width:600px) {
				padding: 0 0 ;
				}
			}

			.slick-slide {
				z-index: 1;
				transition: all 0.5s linear;
				position: relative;
				cursor: pointer;
			
				&.slick-active {
					transform: scale(0.9) translate(-10%) translateY(-4%);
					opacity: 0.7;
					@media screen and (max-width:1200px) {
						transform: scale(0.9) translate(0%) translateY(-4%);;
					}

					&.slick-current {
						transform: scale(1.1) translate(10%) translateY(0%);
						opacity: 1;
						z-index: 9;
						@media screen and (max-width:1200px) {
							transform:  scale(1) translate(0%) translateY(0%);
						}
						.messageContent {
							transform: scale(0.99);
							padding:2rem 1.5rem;
							
							@media screen and(max-width:768px) {
								padding: 0.5rem 0.5rem 0.5rem 0.5rem;
						}
						@media screen and(max-width:600px) {
							padding: 3rem 1.5rem;
					}

						}
						.messageCard{
						    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07);
						}
					}
					@media screen and(max-width:600px) {
						transform: scale(0.9) translate(0) translateY(0%);
						}
				}
			}
			@media screen and(max-width:600px) {
				margin-top: 5rem;
			}
			@media screen and(max-width:800px) {
				margin-top: 2.5rem;
			}
			@media screen and(max-width:320px) {
				margin-top: 0rem;
			}
		}
		.col-100 {
			@media screen and (max-width: 1200px) {
				flex: 0 0 50%;
				max-width: 50%;
			}
			@media screen and (max-width: 800px) {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
		
	}

}
.GrowthComponent{
	.GrowthContent{
		display: flex;
		align-items: center;
	}
}
