@import (css)
url('https://fonts.googleapis.com/css2?family=Exo:wght@500;600;700;800&family=Jost:wght@300;500&family=Poppins:wght@300;400;500;800&family=Saira:wght@600;700&display=swap');

// Theme Variables
@import "~antd/lib/style/themes/default.less";
@import "variables.less";

// Third Party Stylesheets
@import "~antd/dist/antd.less"; // Import Ant Design styles by less entry

// Common and Overrides Stylesheets
@import "./utils/utilities.less";
@import "./common.less";
@import "./override.less";

// Layout Stylesheets
@import "./layout/header.less";
@import "./layout/app-header.less";
@import "./layout/app-sidebar.less";
@import "./layout/footer.less";

// Utils Stylesheets
@import "./utils/container.less";

// Page Stylesheets
@import "./pages/hero-section.less";
@import "./pages/shipment.less";
@import "./pages/message.less";
@import "./pages/best-courier.less";
@import "./pages/growth.less";
@import "./pages/vision.less";
@import "./pages/client.less";
@import "./pages/testimonial.less";
@import "./pages/publicNoti.less";

//section Stylesheets
@import "./sections/about-us.less";
@import "./sections/chanel-partner.less";
@import "./sections/network.less";
@import "./sections/contact-us.less";
@import "./sections/privacy.less";
@import "./sections/track-shipment.less";
@import "./sections/services.less";
@import "./pages/trackshipModal.less";

// Views Stylesheets
@import "./views/auth.less";
