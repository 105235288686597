.contactUsSection {
	background-color: @banner-bg-color;
	padding: 10rem 0;
	// .topLinear();
	box-shadow: 0 0 1rem 1rem #f4f7fc;
	@media screen and (max-width:767px) {
		padding: 5rem 0;
	}
	.contactContainer {
		display: flex;
		// align-items: center;
		justify-content: center;
		flex-direction: column;

		.ant-card {
			border: none;
			max-width: 100%;
			width: 52rem;
			border-radius: 1rem;
			margin: 0px 0px 3rem 0px;
			box-shadow: 0px 0px 3rem 0px rgba(0, 0, 0, 0.06);

			.ant-card-body {
				padding: 2rem 4rem;
				display: flex;
				align-items: center;
				position: relative;

				img {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					z-index: 11;

					&.cardIcon {
						height: 2.4rem;
						width: 2.4rem;

						@media screen and (max-width: 800px) {
							height: 2rem;
							width: 2rem;
						}

						@media screen and (max-width: 600px) {
							height: 1.6rem;
							width: 1.6rem;
							margin-left: -0.4rem;
						}

						@media screen and (max-width: 480px) {
							height: 1.5rem;
							width: 1.5rem;
							margin-left: -0.2rem;
						}
					}
					@media screen and(max-width:600px) {
						height: 18px;
						width: 18px;
					}
					@media screen and(max-width:991px) {
						left: 10px;
					}
					@media screen and(max-width:600px) {
						left: 6px;
					}
					@media screen and(max-width:600px) {
						left: 9px;
					}
				}

				.cardContent {
					margin-left: 4rem;

					span {
						font-family: @primary-font;
						color: @text-input-color;
						font-size: 1.6rem;
						font-weight: 500;
						display: flex;
						@media screen and (max-width: 1400px) {
							font-size: 1.4rem;
						}

						@media screen and (max-width: 1200px) {
							font-size: 1.4rem;
						}
					}

					a {
						font-family: @secondary-font;
						color: @secondary-color;
						font-size: 2.4rem;
						font-weight: 600;

						@media screen and (max-width: 1600px) {
							font-size: 2rem;
						}

						@media screen and (max-width: 1400px) {
							font-size: 1.8rem;
						}

						@media screen and (max-width: 1200px) {
							font-size: 1.5rem;
						}

						@media screen and (max-width: 1024px) {
							font-size: 1.4rem;
						}

						@media screen and (max-width: 768px) {
							font-size: 2rem;
							word-break: break-all;
						}
						@media screen and (max-width: 560px) {
							font-size: 1.4rem;
							word-break: break-all;
						}
					}

					@media screen and (max-width: 480px) {
						padding: 1rem;
					}
					@media screen and (max-width: 600px) {
						margin-left: 3rem;
					}

					@media screen and (max-width: 480px) {
						margin-left: 2rem;
					}
				}

				@media screen and (max-width: 991px) {
					padding: 2rem;
				}
				@media screen and (max-width: 480px) {
					padding: 1rem;
				}
				&::after {
					position: absolute;
					background-color: @banner-bg-color;
					content: "";
					height: 60px;
					width: 60px;
					border-radius: 50%;
					left: 21px;
					z-index: 1;
					@media screen and(max-width:991px) {
						height: 40px;
						width: 40px;
					}
					@media screen and (max-width: 480px) {
						height: 3rem;
						width: 3rem;
						left: 10px;
						// top: -0.7rem;
					}
				}
			}

			@media screen and (max-width: 1600px) {
				width: 42rem;
			}

			@media screen and (max-width: 1400px) {
				width: 39rem;
			}

			@media screen and (max-width: 800px) {
				width: 45rem;
			}
		}

		.cardContainer {
			margin-top: 3rem;
			@media screen and (max-width: 480px) {
				margin-top: 2rem;
			}
		}
	}
	.ant-col-md-10 {
		flex: 0 0 40%;
		max-width: 40%;
		@media screen and(max-width:850px) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	.ant-col-md-15 {
		flex: 0 0 60%;
		max-width: 60%;
		.mapContainer {
			display: flex;
			align-items: self-end;
			position: relative;
		  justify-content: center;

			.cardContainer {
				position: absolute;
				left: 40rem;

				@media screen and (max-width: 1400px) {
					left: 30rem;
				}

				@media screen and (max-width: 1200px) {
					margin-top: 2rem;
				}

				@media screen and (max-width: 480px) {
					margin-top: 2rem;
				}

				.ant-card {
					max-width: 100%;
					// width: 473px;
					border: none;
					border-radius: 1rem;
					background: @white;
					box-shadow: 0px 0px 4rem 0px rgba(0, 0, 0, 0.04);

					.ant-card-body {
						padding: 1rem;

						@media screen and(max-width:1470px) {
							padding: 2rem;
						}

						@media screen and(max-width:1024px) {
							padding: 1.6rem;
						}
						@media screen and(max-width:991px) {
							padding: 0.9rem;
						}
					}

					.cardContent {
						display: flex;
						align-items: baseline;
						max-width: 100%;
						width: 42rem;

						@media screen and(max-width:1470px) {
							width: 100%;
						}

						// .cardIcon {
						// 	height: 1.2rem;
						// 	width: 1.2rem;
						// 	background: linear-gradient(
						// 		315deg,
						// 		#ed008c 0%,
						// 		#ff86cd 100%
						// 	);
						// 	border-radius: 50%;
						// }

						p {
							color: #222;
							font-size: 2rem;
							font-family: @secondary-font;
							font-weight: 500;

							@media screen and(max-width:1400px) {
								font-size: 1.6rem;
							}

							@media screen and(max-width:1024px) {
								font-size: 1.4rem;
							}

							@media screen and(max-width:768px) {
								font-size: 1.2rem;
							}
						}

						@media screen and(max-width:1024px) {
							width: 100%;
						}
						@media screen and(max-width:991px) {
							.ant-col {
								padding-left: 0.5rem;
								padding-right: 0.5rem;
							}
						}
					}
				}

				@media screen and (max-width: 768px) {
					margin-top: 2rem;
				}

				@media screen and (max-width: 600px) {
					position: inherit;
					left: 0;
				}
			}

			.centerBottomImg {
				position: absolute;
				top: -50px;
				right: 0;
			}

			.headingcontent {
				position: relative;
				.headerFonts();
				font-size: 2.8rem;
				text-transform: uppercase;
				text-align: center;

				&::after {
					position: absolute;
					background-color: #ed008c;
					height: 0.3rem;
					width: 1.6rem;
					content: "";
					bottom: -1rem;
					left: 4.5rem;
					right: 0;
					margin: 0 auto;
					border-radius: 2rem;
				}

				&::before {
					position: absolute;
					background-color: #ed008c;
					height: 0.3rem;
					width: 1.8rem;
					content: "";
					bottom: -1rem;
					left: -4.5rem;
					margin: 0 auto;
					right: 0;
					border-radius: 2rem;
				}

				.bottomLine {
					position: absolute;
					background-color: #ed008c;
					height: 0.3rem;
					width: 2rem;
					content: "";
					bottom: -1rem;
					right: 0;
					left: 0;
					margin: 0 auto;
					border-radius: 2rem;
				}
				@media screen and (max-width: 991px) {
					font-size: 1.8rem;
				}
				@media screen and (max-width: 600px) {
					font-size: 1.7rem;
				}

				@media screen and (max-width: 480px) {
					font-size: 1.5rem;
				}
			}

			.mapImg {
				img {
					width: 100%;
				}
				@media screen and (max-width: 1200px) {
					width: 80%;
				}

				img {
					@media screen and (max-width: 1200px) {
						height: auto;
						width: 100%;
					}
				}
			}

			@media screen and (max-width: 1024px) {
				width: 100%;
				margin-left: 2.5rem;
			}

			@media screen and (max-width: 600px) {
				margin-left: 0px;
				align-items: center;
				flex-direction: column;
				flex-wrap: wrap;
			}
		}
		@media screen and(max-width:850px) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	// @media screen and(max-width:1024px) {
	//   .col-100{
	//     flex:0 0 50%;
	//     max-width:50%;
	//   }
	// }
}

// - contact page

.contact {
	background: @banner-bg-color;
	box-shadow: 0px 2px 2rem 0px rgba(0, 0, 0, 0.12);
	padding: 10rem 0;
	@media screen and (max-width:767px) {
		padding: 5rem 0;
	}

	.heading {
		display: flex;
		align-items: flex-start;
		flex-direction: column;

		h1 {
			b {
				display: block;
			}
		}
	}
	img {
		height: auto;
		width: 100%;
	}
}
.formData {
	padding: 10rem 0;
	@media screen and (max-width:767px) {
		padding: 5rem 0;
	}
	.ant-row {
		justify-content: space-between;
	}
}
.contactForm {
	box-shadow: 0px 2px 1.4rem 0px rgba(0, 0, 0, 0.15);
	padding: 4rem 3rem;
	@media screen and (min-width: 1440px) {
		padding: 4rem 10rem;
	}
	@media screen and (max-width:820px) {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
	@media screen and(max-width:767px) {
		margin-bottom: 35px;
		width: 100%;
	}

	h2 {
		color: #000;
		font-family: @primary-font;
		font-size: 2.9rem;
		font-style: normal;
		font-weight: 600;
		@media screen and(max-width:991px) {
			font-size: 2.2rem;
		}
		@media screen and(max-width:600px) {
			font-size: 1.8rem;
		}
	}
}
.formData {
	@media screen and (max-width:600px) {
		padding: 6rem 0;
	}
	h4 {
		color: #000;

		font-family: @forth-banner-font;
		font-size: 2rem;

		font-weight: 400;
		@media screen and(max-width:991px) {
			font-size: 1.9rem;
		}
	}

	.content {
		margin: 3rem 0px;
		.icons {
			width: 30px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: flex-end;
		}
		.ant-col {
			padding-left: 0px;
			padding-right: 0px;
			//margin-left: -6px;
		}

		.pera {
			color: #000;
			//color: @primary-color;
			font-family: @secondary-font;
			font-size: 2.1rem;
			font-style: normal;
			font-weight: 300;
			display: flex;
			align-items: center;

			@media screen and(max-width:991px) {
				font-size: 1.8rem;
			}
		}
		@media screen and(max-width:768px) {
			margin: 1rem 0px;
		}

		@media screen and(max-width:480px) {
			margin: 0.5rem 0px;
		}
		&.ant-row {
			@media screen and(max-width:600px) {
				flex-flow: inherit;
			}
		}
	}

	.contactIcon {
		ul {
			margin: 0;
			padding: 0;
			display: flex;

			li {
				margin-right: 2rem;
				margin-right: 2rem;
				border: 1px solid @primary-color;
				border-radius: 50%;
				height: 4rem;
				width: 4rem;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				.transition(0.5s, linear);
				a {
					align-items: center;
					display: flex;
					justify-content: center;
					img {
						height: 1.5rem;
						width: 1.5rem;
					}
				}
				&:hover {
					border: 1px solid @secondary-color;
				}
			}
		}
	}

	form {
		font-family: @forth-banner-font;
		&.getInTouch {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			// label.ant-form-item-required:not(
			// 		.ant-form-item-required-mark-optional
			// 	)::before {
			// 	display: block; content: "";
			// }
			.captcha_relative {
				width: calc(50% - 8px);
				position: relative;
				.reloadIcon {
					position: absolute;
					right: 10px;
					top: 33px;
					cursor: pointer;
					width: 25px;
					height: 25px;
					svg {
						width: 25px;
						fill: @secondary-color;
						height: 25px;
					}
				}
			}
			.inputText {
				width: calc(50% - 8px);
				#nest-messages_captcha {
					color: @secondary-color;
					border: 1px solid #d0d5dd;
					font-size: 180%;
					background-color: rgba(@secondary-color, 0.1);
					border-radius: 8px;
					padding: 0 10px;
					-webkit-user-modify: read-only;
				}
				&.textareaInput {
					width: 100%;
				}
				&.captchaRelative {
					position: relative;
					width: 100%;
					.captchaCode {
						margin-bottom: 0 !important;
						
					}
					label{
						&::before{
							display: none !important;
						}
					}
				}
				&.recaptchaText {
					margin-top: 0px;
					label{
						&::before{
							display: none !important;
						}
					}
				}
				.ant-col {
					// padding:4rem 4rem;
					padding-left: 0px;
					padding-right: 0px;
					@media screen and(max-width:800px) {
						padding: 0px 0px;
					}
				}
			}
			.ant-input {
				//height: 4rem;
				height: auto;
				border-radius: 0.8rem;
				border: 1px solid #d0d5dd;
				padding: 0.8rem 1.3rem;
				font-size: 1.4rem;
			}
			.footerBtn {
				margin-top: 1rem;
				width: 100%;
				button {
					padding: 0.5rem 0px;
					height: 4rem;
					width: 100%;
					border-radius: 0.5rem;
				}
			}
			.ant-input-number {
				width: 100%;
				padding: 0.5rem 0.7rem;
				border: 1px solid #d0d5dd;
			}
			label {
				font-size: 1.4rem;
				font-weight: 500;

				&::before {
					color: #c00;
					content: "*";
					display: inline-block;
					font-family: SimSun, sans-serif;
					font-size: 16px;
					line-height: 1;
					margin-right: 4px;
				}
			}
			.checkbox {
				display: flex;
				.checkContent {
					padding-left: 1rem;
					span {
						@media screen and(max-width:991px) {
							font-size: 1.5rem;
						}
						@media screen and(max-width:600px) {
							font-size: 1.2rem;
						}
					}
				}
			}

			@media screen and(max-width:991px) {
				padding: 0rem;
			}
			@media screen and(max-width:480px) {
				padding: 0;
				justify-content: flex-start;
				flex-direction: column;
				.inputText {
					width: 100%;
					&.captchaRelative {
						margin-bottom: 0;
					}
				}
				.captcha_relative {
					width: 100%;
				}
			}
			@media screen and(max-width:800px) {
				margin-top: 1rem;
			}

			.getInTouch {
				.ant-row {
					margin: 1.2rem 0px;
					@media screen and(max-width:600px) {
						margin: 0px 0px;
					}
				}
				@media screen and(max-width:600px) {
					.ant-form-item {
						margin-bottom: 0.4rem;
					}
				}
			}
			.ant-col-12 {
				@media screen and(max-width:800px) {
					flex: 0 0 100%;
					max-width: 100%;
				}
			}
		}
	}
}
// .mapBg {
// 	background-image: url("../../../images/mapLocationImg.png");
// }
.map_section {
	iframe {
		border: 0;
	}
}


//  location alert modal

.locationAlert {
	.modalHeading {
		margin: 10px 0px;
		h4 {
			font-size: 1.9rem;
			font-family: @forth-banner-font;
			font-weight: 500;
			color: #000;
		}
	}
	h4 {
		font-size: 1.5rem;
		font-family: @secondary-font;
		font-weight: 500;
		color: @primary-color;
	}
	p {
		font-size: 1.5rem;
		font-family: @forth-banner-font;
	}
	.ant-divider {
		margin: 5px 0px;
	}
	svg {
		height: 18px;
		width: 18px;
	}
}