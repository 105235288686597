.TestimonialSection {
	padding: 10rem 0 0;

	@media screen and (max-width:767px) {
		padding: 5rem 0;
	}

	.TestimonialContent {
		.headingSpan {
			&:before {
				right: -13.1rem;

				@media screen and (max-width: 1024px) {
					right: -12.5rem;
				}

				@media screen and (max-width: 600px) {
					right: -12.5rem;
				}

				@media screen and (max-width: 480px) {
					right: -11.3rem;
				}
			}
		}

		.testCard {
			background-color:transparent;
			border-radius: 2rem;
			margin-bottom: 3rem;
			max-height: 415px;
			height: 100%;

			.ant-card-body {
				padding: 0px 0px;
				position: relative;
			}

			img {
				// height:100%;
				// width:100%;
				border-radius: 2rem;
			}

			.playBtn {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 4;
				background-color: white;
				height: 8rem;
				width: 8rem;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;

				@media screen and(max-width:1024px) {
					img {
						height: 3rem;
						width: 3rem;
					}
				}
			}

			// &::before {
			// 	content: "";
			// 	background-color: white;
			// 	height: 12rem;
			// 	width: 12rem;
			// 	position: absolute;
			// 	top: 50%;
			// 	left: 50%;
			// 	transform: translate(-50%, -50%);
			// 	z-index: 1;
			// 	opacity: 0.2;
			// 	border-radius: 50%;
			// }

			// &::after {
			// 	content: "";
			// 	background-color: white;
			// 	height: 10rem;
			// 	width: 10rem;
			// 	position: absolute;
			// 	top: 50%;
			// 	left: 50%;
			// 	transform: translate(-50%, -50%);
			// 	z-index: 1;
			// 	opacity: 0.5;
			// 	border-radius: 50%;
			// }
			@media screen and (max-width: 991px) {
				max-height: 100%;
				height: 100%;
			}

		}

		.readMoreLink {
			margin-top: 3rem;
		}

		.subHeading {
			.afterLine {
				left: 18rem;

				@media screen and(max-width:1024px) {
					left: 14.5rem;
					top: 0.8rem;
				}
			}
		}

		.headingContent {
			font-family: "Exo", sans-serif;
			font-weight: 800;
			color: #000;
			font-size: 6rem;
			margin-bottom: 0;
			position: relative;

			@media screen and (max-width: 1600px) {
				font-size: 5.2rem;
			}

			@media screen and (max-width: 1420px) {
				font-size: 4.8rem;
			}

			@media screen and (max-width: 1200px) {
				font-size: 3.5rem;
			}

			@media screen and (max-width: 991px) {
				font-size: 3.3rem;
			}


		}
	}

	.testSlider {
		margin: 0px 0px 0px 0px;

		.ant-card {
			border: none;
			box-shadow: 0px 0px 5rem 0px rgba(0, 0, 0, 0.06);
			margin: 2rem 0px;

			.ant-card-body {
				display: flex;
				padding: 3.5rem;

				@media screen and (max-width: 1400px) {
					padding: 3rem;
				}

				@media screen and (max-width: 1024px) {
					padding: 2rem;
				}

				@media screen and (max-width: 480px) {
					padding: 1rem;
				}

				.sliderContanet {
					margin-left: 3rem;

					@media screen and (max-width: 1400px) {
						margin-left: 0px;
					}
				}
			}

			.sliderContenet {
				.contentPera {
					p {
						margin-top: 0;
					}
				}

				@media screen and (max-width: 1400px) {
					margin-left: 0px;
				}

				.ant-avatar {
					height: 8rem;
					width: 8rem;
				}

				.peraFonts {
					font-family: @secondary-font;
					font-size: 2rem;
					font-weight: 300;

					@media screen and (max-width: 1400px) {
						font-size: 2rem;
					}

					@media screen and (max-width: 1200px) {
						font-size: 1.8rem;
					}

					@media screen and (max-width: 1024px) {
						padding-top: 0px;
						font-size: 1.5rem;
					}

					@media screen and (max-width: 480px) {
						font-size: 1.2rem;
					}

					// p {
					// 	@media screen and (max-width: 1200px) {
					// 		margin-top: 0px;
					// 	}

					// 	@media screen and (max-width: 480px) {
					// 		font-size: 1.1rem;
					// 	}
					// }
				}

				.userInfo {
					display: flex;
					align-items: center;

					.userDetails {
						margin-left: 2rem;

						.userName {
							font-family: @primary-font;
							font-weight: 600;
							font-size: 2.4rem;
							margin-bottom: 0px;

							@media screen and (max-width: 1400px) {
								font-size: 2.2rem;
							}

							@media screen and (max-width: 1024px) {
								font-size: 2rem;
							}

							@media screen and (max-width: 991px) {
								font-size: 1.8rem;
							}

							@media screen and (max-width: 480px) {
								font-size: 1.5rem;
							}
						}

						span {
							font-family: @secondary-font;
							font-weight: 300;
							font-size: 1.6rem;

							@media screen and (max-width: 1400px) {
								font-size: 1.4rem;
							}

							@media screen and (max-width: 480px) {
								font-size: 1.1rem;
							}
						}

						@media screen and (max-width: 600px) {
							margin-left: 1rem;
						}
					}

					.ant-avatar {
						@media screen and (max-width: 1400px) {
							height: 7rem;
							width: 7rem;
						}

						@media screen and (max-width: 1024px) {
							height: 5rem;
							width: 5rem;
						}

						@media screen and (max-width: 600px) {
							height: 3.5rem;
							width: 3.5rem;
						}

						@media screen and (max-width: 300px) {
							height: 100%;
							width: 100%;
						}
					}
				}

				@media screen and (max-width: 480px) {
					padding-left: 0.5rem;
				}
			}
		}

		.ant-col-2 {
			@media screen and (max-width: 1400px) {
				max-width: 20%;
				flex: 0 0 20%;
			}

			@media screen and (max-width: 1024px) {
				max-width: 15%;
				flex: 0 0 15%;
			}

			img {
				@media screen and (max-width: 1024px) {
					height: 4rem;
					width: 4rem;
				}

				@media screen and (max-width: 600px) {
					// height:100%;
					width: 100%;
				}
			}
		}

		.ant-col-22 {
			@media screen and (max-width: 1400px) {
				max-width: 80%;
				flex: 0 0 80%;
			}
		}

		.customScrollbarTrack {
			background-color: #efefef;
			height: 100%;
			right: 0px;
			top: 0;
			border-radius: 3rem;
		}

		.customScrollbarThumb {
			border-radius: 2rem;
			background: linear-gradient(315deg, #ed008c 0%, #ff86cd 100%);
			width: 1.2rem;
		}

		.scrollBar {
			min-height: 400px;

			.scrollBox {
				padding-inline: 1rem 2rem;

				.quoteIcon {
					img {
						width: 100%;
					}

					// img {
					// 	width: 60px;
					// 	height: 60px;
					// }

					@media screen and (max-width:600px) {
						width: 30px;
						padding: 0;
					}
				}
			}
		}

		@media screen and (max-width: 800px) {
			margin: 0px 0px;
		}
	}

	.ant-col-10 {
		@media screen and (max-width:1600px) {
			flex: 0 0 50%;
			max-width: 50%;
		}
		@media screen and (max-width:991px) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	.ant-col-14 {
		@media screen and (max-width:1600px) {
			flex: 0 0 50%;
			max-width: 50%;
		}
		@media screen and (max-width:991px) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
}