footer {
	background-color: #191919;
	padding: 50px 0px;

	.FooterContainer {
		position: relative;
		.ant-col{padding-left: 0;padding-right: 0;}
		.usefulLinks {
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			.linkList {
				margin-top: 30px;
				display: flex;
				justify-content: center;
				flex-direction: column;

				a {
					margin: 0px 0px 20px 0px;
					color: @white;
					font-size: 1.8rem;
					font-weight: 400;
					text-transform: capitalize;
					font-family: @primary-font;
					.transition(0.5s,ease-in-out);
					cursor: pointer;
					@media screen and (max-width: 1400px) {
						font-size: 1.6rem;
					}
					@media screen and (max-width: 1200px) {
						font-size: 1.5rem;
					}
					@media screen and (max-width: 1024px) {
						font-size: 16px;
						margin: 0px 0px 10px 0px;
					}
					&:hover, &.active {
						color: @primary-color;
					}
				}

				@media screen and (max-width: 400px) {
					margin-top: 15px;
				}
			}
			@media screen and (max-width:767px) {
				margin-top: 35px;
			}
		}

		// .ant-col-5 {
		// 	@media screen and(max-width:800px) {
		// 		flex: 0 0 33.33%;
		// 		max-width: 33.33%;
		// 	}
		// 	@media screen and(max-width:420px) {
		// 		flex: 0 0 50%;
		// 		max-width: 50%;
		// 	}
		// }
		// .ant-col-4 {
		// 	@media screen and(max-width:800px) {
		// 		flex: 0 0 33.33%;
		// 		max-width: 33.33%;
		// 	}
		// 	@media screen and(max-width:420px) {
		// 		flex: 0 0 100%;
		// 		max-width: 100%;
		// 	}
		// }
		// .ant-col-10 {
		// 	@media screen and(max-width:800px) {
		// 		flex: 0 0 100%;
		// 		max-width: 100%;
		// 	}
		// }
	}

	.socialIcons {
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
		padding-top: 10px;
		ul {
			display: flex;
			align-items: center;
			margin: 0px 0px;
			padding: 0px 0px;

			li {
				margin: 0px 25px 0px 0px;

				a {
					background-color: @grey-color;
					width: 50px;
					height: 50px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;
					.transition(0.5s, ease-in-out);
					.icon {
						width: 22px;
						height: 22px;
						padding: 0;
						margin: 0;
						// @media screen and(max-width:600px) {
						// 	width: 16px;
						// 	height: 16px;
						// }
					}
					svg.icon {
						path {
							fill: @secondary-color;
						}
						// @media screen and(max-width:1200px) {
						// 	width: 17px;
						// 	height: 17px;
						// }
						// @media screen and(max-width:480px) {
						// 	width: 12px;
						// 	height: 12px;
						// }
					}

					&:hover {
						background: linear-gradient(
							315deg,
							#ed008c 0%,
							#ff86cd 100%
						);

						.icon {
							filter: invert(100%);
						}

						svg.icon {
							path {
								fill: @white;
							}
						}
					}
					// @media screen and(max-width:1200px) {
					// 	width: 35px;
					// 	height: 35px;
					// }
					// @media screen and(max-width:480px) {
					// 	width: 25px;
					// 	height: 25px;
					// }
				}
				@media screen and (max-width: 1200px) {
					margin: 0px 15px 0px 0px;
				}
				@media screen and (max-width: 400px) {
					margin: 0px 10px 0px 0px;
				}
			}
			// @media screen and(max-width:1200px) {
			// 	margin-top: 20px;
			// }
			// @media screen and (max-width: 400px) {
			// 	margin-top: 10px;
			// }
		}
	}

	.leftFooterContent {
		padding-right: 22px;
		.footerPera {
			color: @white;
			font-family: @secondary-font;
			font-size: 22px;
			font-weight: 300;
			padding: 35px 0px 5px 0px;
			text-transform: capitalize;
	
			@media screen and (max-width: 1024px) {
					padding-bottom: 15px;
			}
			@media screen and (max-width: 767px) {
	
				padding-bottom: 5px;
			}
	
		}
	}
	.footerHeading {
		color: @white;
		font-family: @secondary-font;
		font-size: 2.2rem;
		font-weight: 400;
		span {
			position: relative;
			&::after {
				position: absolute;
				content: "";
				background-color: @primary-color;
				height: 2px;
				width: 100%;
				bottom: -10px;
				left: 0;
			}
		}
		@media screen and (max-width: 1400px) {
			font-size: 22px;
		}
		@media screen and (max-width: 1024px) {
			font-size:19px;
		}
		@media screen and (max-width: 767px) {
			font-size: 22px;	
		}
	}
	.footerLogoWrapper {
		max-width: 100%;
		width: 280px;
		svg {
			width: 100%;
			@media screen and (max-width: 1024px) {
				width: 100%;
			}
		}
		@media screen and (max-width: 480px) {
			width: 200px;
		}
	}
	.QrCode {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-direction: column;
		.scanContainer {
			margin-top: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			
			@media screen and (max-width: 400px) {
				margin-top: 32px;
			}
		}
		img {
			margin-top: 30px;
			width: 114px;
			// margin-left: auto;
			// margin-right: auto;
		}
		@media screen and (max-width: 767px) {
			margin-top: 35px;
		}
	}
	.rightFooterContent {
		position: relative;
		//margin-left: 25%;
		padding-left: 2.5rem;
		img {
			background-color: @white;
			padding: 10px;
			border-radius: 10px;
		}
		.rightFooterPera {
			font-family: @secondary-font;
			font-size: 2.2rem;
			font-weight: 400;
			color: @yellow-color;
			margin-top: 25px;
			@media screen and (max-width: 1400px) {
				font-size: 1.9rem;
			}
			@media screen and (max-width: 1024px) {
				//font-size: 1.6rem;
			}
			@media screen and (max-width: 480px) {
				//font-size: 1.3rem;
				margin-top: 20px;
			}
		}
		.subscribeLink {
			color: @white;
			font-size: 2.2rem;
			font-family: @secondary-font;
			margin: 20px 0px 10px 0px;
			@media screen and (max-width: 1400px) {
				font-size: 1.8rem;
			}
			@media screen and (max-width: 1024px) {
				font-size: 1.5rem;
			}
			@media screen and (max-width: 480px) {
				font-size: 1.3rem;
			}
		}
		.ant-form {
			display: flex;
			align-items: center;
			.ant-form-item {
				position: relative;
				.ant-form-item-control-input {
					.ant-form-item-control-input-content {
						.ant-input {
							background-color: transparent;
							border-radius: 50px;
							border: 1px solid rgba(255, 255, 255, 0.2);
							height: 50px;
							font-family: @secondary-font;
							color: @white;
							font-weight: 300;
							font-size: 2.2rem;
							margin-top: 10px;
							@media screen and (max-width: 1300px) {
								font-size: 20px;
							}
							@media screen and (max-width: 1024px) {
								font-size: 16px;
							}
							@media screen and (max-width: 480px) {
								font-size: 13px;
							}
						}
					}
				}
				.ant-col {
					padding-left: 0px;
					padding-right: 0px;
				}
			}
			.signUpBtn {
				background: @primary-color;
				border: none;
				height: 50px;
				border-radius: 50px;
				padding: 6px 32px;
				position: absolute;
				bottom: 24px;
				left: 240px;
				a {
					color: @white;
					font-size: 2.2rem;
					text-transform: capitalize;
					font-family: @secondary-font;
					font-weight: 400;
					@media screen and (max-width: 1300px) {
						font-size: 21px;
					}
					@media screen and (max-width: 1024px) {
						font-size: 16px;
					}
					@media screen and (max-width: 480px) {
						font-size: 13px;
					}
				}
				@media screen and (max-width: 1300px) {
					padding: 4px 28px;
					left: 200px;
				}
				@media screen and (max-width: 1024px) {
					padding: 4px 20px;
					left: 180px;
				}
				@media screen and (max-width: 768px) {
					left: 70%;
					right: 0;
					bottom: 0;
				}
			}
			@media screen and (max-width: 768px) {
				display: block;
			}
		}
		&::after {
			position: absolute;
			height: 100%;
			width: 1px;
			background-color: rgba(255, 255, 255, 0.2);
			content: "";
			top: 0;
			left: 0;
			@media screen and(max-width:1034px) {
			//	left: -65px;
			}
			@media screen and(max-width:800px) {
				display: none;
			}
		}
		@media screen and(max-width:767px) {
			margin-top: 35px;
			padding-left: 0;
		}
		img {
			width: 28rem;
			max-width: 100%;
			height: auto;
			background-color: @white;
			padding: 1.5rem;
			border-radius: 1.5rem;
			@media screen and(max-width:767px) {
				width: 20rem;
			}
			@media screen and(max-width:400px) {
				width: 260px;
				padding: 1rem;
			}
		}
		@media screen and (max-width: 480px) {
			margin-top: 40px;
		}
	}
	@media screen and(max-width:1024px) {
		padding: 25px 0px;
	}
}
.bottomFooter {
	background-color: @primary-color;
	padding: 20px 0px;
	.bottomFooterLinks {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		p {
			margin: 0;
			padding: 0;
			color: @white;
			font-size: 1.8rem;
			font-family: @secondary-font;
			font-weight: 300;
			// cursor: pointer;
			@media screen and(max-width:1200px) {
				font-size: 1.6rem;
			}
			// @media screen and(max-width:767px) {
			// 	font-size: 1.3rem;
			// }
			@media screen and (max-width: 480px) {
				// font-size: 1.1rem;
				text-align: center;
			}
		}

		.rightSideLinks {
			a {
				padding: 10px 20px;
				color: @white;
				font-weight: 300;
				font-family: @secondary-font;

				font-size: 1.6rem;
				// @media screen and(max-width:767px) {
				// 	font-size: 1.5rem;
				// }
				// @media screen and(max-width:600px) {
				// 	font-size: 1.3rem;
				// }
				// @media screen and (max-width: 480px) {
				// 	font-size: 1.1rem;
				// }
				@media screen and(max-width:390px) {
					padding: 10px 12px;
				}
			}
		}

		// &::after {
		// 	position: absolute;
		// 	background-color: @white;
		// 	width: 1px;
		// 	height: 80%;
		// 	content: "";
		// 	left: 135px;
		// 	top: 4px;
		// 	@media screen and(max-width:800px) {
		// 		left: 125px;
		// 	}
		// 	@media screen and(max-width:600px) {
		// 		left: 115px;
		// 	}
		// }
		@media screen and(max-width:600px) {
			flex-wrap: wrap;
			justify-content: center;
		}
	}
	@media screen and(max-width:768px) {
		padding: 10px 0px;
	}
}
