.privacyContainer {
	background-color: @banner-bg-color;
	box-shadow: 0px 0.2rem 2rem 0px rgba(0, 0, 0, 0.12);
	padding: 10rem 0;
	img {
		height: auto;
		width: 100%;
	}
	@media screen and (max-width: 767px) {
		padding: 5rem 0px 6rem;
	}
	.pageNavigation{bottom: -10rem;
		@media screen and (max-width: 768px) {
			bottom: -6rem;
		}
	}
}
.privacyPolicy {
	li{
		margin: 2rem 0px 4rem 0px;
		padding-bottom: 2rem;
		border-bottom: 1px solid rgba(0,0,0,.20);
		font-size: 2.2rem;
		list-style: inherit;
		&:last-child{border-bottom: 0;}
		@media screen and (max-width: 600px) {
			margin: 3rem 0px 2rem 0px;
		}
		ol,ul{
			li{
				margin: 0;
				border-bottom: 0;
				padding-bottom: 1.5rem;
				font-size: 2rem;
				@media screen and (max-width: 600px) {
					font-size: 1.5rem;
					word-wrap: break-word;
				}
			}
		}
		ul{
			&.alphaList{list-style:lower-alpha;}
			&.bulletList{list-style:disc;}
		}
	.privacyHeading {
		color: #000;

		font-family: @primary-font;
		font-size: 2.6rem;

		font-weight: 300;
		strong{font-weight: 400;}
		@media screen and (max-width: 600px) {
			font-size: 1.9rem;
		}
		@media screen and (max-width: 480px) {
			font-size: 1.8rem;
		}
	}
	.privacyPera {
		font-family: @secondary-font;
		font-size: 2rem;
		color: #000;
		font-weight: 300;
		p{font-size: 2rem;
			@media screen and (max-width: 600px) {
				font-size: 1.5rem;
			}
		}
		ol{
			padding-left: 25px;
			@media screen and (max-width: 600px) {
				padding-left: 16px;
			}
		}
	}
}
}
