.container,
.container-fluid {
	max-width: 100%;
	width: 100%;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	margin-right: auto;
	margin-left: auto;
}

.container {
	// width: clamp(32rem, 98vw, 1620px);
	height: 100%;
	position: relative;

	@media (min-width: 992px) {
		max-width: 960px;
	}
	@media (min-width: 1199px) {
		max-width: 1140px;
	}
	@media (min-width: 1440px) {
		max-width: 1260px;
	}
	@media (min-width: 1600px) {
		max-width: 1560px;
	}
	@media (min-width: 1800px) {
		max-width: 1620px;
	}
}
.ant-col {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	@media screen and (max-width:480px) {
		padding-left: 0.5rem;
	padding-right: 0.5rem;
	}
}
