.shipmentSection {
	padding: 10rem 0;
	.boxImg {
		position: absolute;
		top: -3rem;
		right: 22rem;
		img {
			height: auto;
			width: 100%;
		}
	}
	@media screen and (max-width:767px) {
		padding: 8rem 0 5rem;
	}
	.trackImg {
		@media screen and(max-width:600px) {
			margin-top: 3rem;
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	.shipmentRight {
		@media screen and(max-width:768px) {
			width: 100%;
		}

		.centerBottomImg {
			height: 10rem;
			width: 10rem;
			position: absolute;
			right: 6rem;
			/* top: 0; */
			bottom: 0px;
			img {
				width: 100%;
				height: 100%;
			}
			@media screen and(max-width:1200px) {
				right: 0rem;
			}
			@media screen and(max-width:991px) {
				display: none;
			}
		}
		@media screen and(max-width:768px) {
		margin-top: 20px;
		}
	}

	.shipmentContent {
		form {
			.ant-form-item-control {
				padding-left: 0;
			}
			.ant-input {
				margin-top: 1.4rem;

				@media screen and(max-width:600px) {
					margin-top: 0px;
					font-size: 1.5rem;
					height: 4rem;
				}
			}

			.headerBtn {
				button {
					margin-top: 0.6rem;
				}
			}

			.ant-form-item {
				@media screen and(max-width:480px) {
					margin-bottom: 1.6rem;
				}
			}
		}

		p {
			margin-bottom: 1rem;
		}

		.radioBtnWrap {
			.ant-form-item{
				margin-bottom: 0;
			}
			.ant-col {
				padding-left: 0;
				padding-right: 0;
				.ant-radio-group {
					.ant-radio-wrapper {
						span {
							font-family: @secondary-font;
							font-size: 1.8rem;
							font-weight: 300;
							color:@black;
						}
						.ant-radio-inner {
							width: 20px;
							height: 20px;
							border-width: 2px;
							border-color: @primary-color;
							&::after {
								background-color: @primary-color;
							}
						}

					}
				}
			}
		}
		@media screen and(max-width:768px) {
			width: 100%;
		}
	}

	.shipmentRight {
		margin-left: 5rem;

		@media screen and(max-width:1100px) {
			margin-left: 2.5rem;
		}

		@media screen and(max-width:800px) {
			margin-left: 0px;
		}
	}

	.col-center {
		display: flex;
		align-items: center;
	}

	// @media screen and(max-width:600px) {
	// 	margin-top: 0px;
	// }

	.trackShipmentForm {
		.ant-col {
			padding-left: 0px;
			padding-right: 0px;
		}
	}

}

// international table 
.internationalRow {
	margin: 20px 0;

	.internationalBooking {
		width: 100%;
		margin: 0 15px 0px 15px;
		border: 1px solid rgba(0, 0, 0, 0.2);
		@media screen and(max-width:480px) {
			margin: 0 5px;
		}

		.internationalCol {
			padding: 0;

			.ant-row {
				margin: 0 !important;
			}
		}

		.ant-row {
			.ant-col {
				border-right: 1px solid rgba(0, 0, 0, 0.2);
				border-bottom: 1px solid rgba(0, 0, 0, 0.2);
				font-size: 17px;
				font-family: @secondary-font;
				font-weight: 300;
				text-align: left;
				padding: 10px;
				@media screen and(max-width:767px) {
					flex: 0 0 50%;
					max-width: 50%;
					&:nth-child(even) {
						border-right: none;
					}
				}
				@media screen and(max-width:480px) {
					&:nth-child(even) {
						border-right: none;
					}
				}
				&:last-child {
					border-right: none;
				}
			}
		}


	}
}

.dataTitle {
	background-color: rgba(237, 0, 140,0.7);
	font-size: 20px;
	font-family: @secondary-font;
	font-weight: 500;
	text-align: left;
	padding: 10px;
	color: @white;
}

.internationalTableList {
	margin-bottom: 30px;
	.internationalTable {
		width: 100%;
		margin-bottom: 30px;

		thead {
			position: sticky;
			top: 0;
			tr {
				th {
					background-color: rgba(237, 0, 140,);
					font-size: 20px;
					font-family: @secondary-font;
					font-weight: 500;
					text-align: left;
					padding: 10px;
					color: @white;
				}
			}
		}

		tbody {
			tr {
				td {
					font-size: 16px;
					font-family: @secondary-font;
					font-weight: 300;
					text-align: left;
					padding: 10px;
				}

				&:nth-child(even) {
					background-color: @white;
				}

				&:nth-child(odd) {
					background-color: #ebebeb57;
				}
			}
		}
	}
	.timelineData {
		font-weight: 500;
		color: @black;
		.timelineLocation {
			font-weight: 400;
			color:#6a6a6a;
		}
	}
}