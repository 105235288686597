.trackContainer {
	background-color: @banner-bg-color;
	box-shadow: 0px 0.2rem 2rem 0px rgba(0, 0, 0, 0.12);
	padding: 10rem 0;
	@media screen and (max-width: 767px) {
		padding: 5rem 0;
	}
	img {
		height: auto;
		width: 100%;
	}

	.trackContent {
		.heading {
			h1 {
				font-size: 6rem;
				@media screen and (max-width: 1200px) {
					font-size: 4.2rem;
				}

				@media screen and (max-width: 1024px) {
					font-size: 4rem;
				}

				@media screen and (max-width: 991px) {
					font-size: 3.5rem;
				}

				@media screen and (max-width: 480px) {
					font-size: 2.6rem;
				}

				@media screen and (max-width: 320px) {
					font-size: 2.4rem;
				}
			}
		}
	}
}
@media screen and (max-width: 800px) {
	margin-top: 3rem;
}

.trackConsignment {
	margin-top: 0rem;
	padding-top: 5rem;
	.title {
			font-size: 3.5rem;
			font-weight: 600;
			color: #000000;
	
			@media screen and (max-width: 800px) {
				font-size: 2.5rem;
			}
		}
	
		.subTitle {
			font-size: 2.3rem;
			color: #ff0000;
			margin-bottom: 4rem;
	
			@media screen and (max-width: 800px) {
				font-size: 1.8rem;
			}
		}
	.trackConsignmentTitle {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		justify-content: space-between;
	}
	.blcHeader {
		border-radius: 2rem 2rem 0 0;
		background: @secondary-color;
		padding: 1.5rem 6.5rem;
		display: flex;
		align-items: flex-start;

		h4 {
			color: @white;
			text-align: left;
			font-family: @primary-font;
			font-size: 2.2rem;
			letter-spacing: 1px;
			margin-bottom: 0px;
			padding-right: 1rem;
			span {
				margin-left: 5px;
			}
			// span {
			// 	display: block;
			// }
			// @media screen and (max-width: 1200px) {
			// 	font-size: 1.8rem;
			// }
			@media screen and (max-width: 800px) {
				font-size: 1.6rem;
				display: flex;
				span {
					display: block;
					word-wrap: break-word;
				}
			}

			// @media screen and (max-width: 600px) {
			// 	font-size: 1.2rem;
			// }
			@media screen and (max-width: 480px) {
				flex-direction: column;
			}
		}
		@media screen and (max-width: 1198px) {
			padding: 1.5rem 3rem;
			h4 {
				font-size: 20px;
			}
		}
		@media screen and (max-width: 800px) {
			width: 100%;
			margin-bottom: 0px;
			flex-direction: column;
			border-radius: 0.5rem;
			h4 {
				font-size: 18px;
			}
		}
		@media screen and (max-width: 480px) {
			padding: 1.5rem 2rem;
			h4 { 
				span{
					margin-left: 0;
				}
			}
		}
	}
	.ant-table {
		.ant-table-container {
			.ant-table-thead {
				th {
					&.ant-table-cell {
						//background-color: @primary-color;

						border-right: none !important;
						color: #fff;
						font-family: @forth-banner-font;
						font-size: 2rem;
						font-weight: 500;

						letter-spacing: 1.1px;
						text-transform: uppercase;
						@media screen and (max-width: 800px) {
							font-size: 1.8rem;
						}
						@media screen and (max-width: 480px) {
							//font-size: 1.4rem;
							padding: 0.5rem 0.5rem;
						}
					}
				}
			}

			.ant-table-tbody {
				font-family: @forth-banner-font;
				font-size: 1.5rem;

				font-weight: 400;

				letter-spacing: 1.1px;
				text-transform: uppercase;

				.ant-table-cell {
					@media screen and (max-width: 800px) {
						//font-size: 1.3rem;
					}
					@media screen and (max-width: 480px) {
						//	font-size: 1rem;
						padding: 0.7rem 0.7rem;
					}
				}
			}
		}
	}
	.titleColor {
		border: 1px solid @secondary-color;
		background-color: @primary-color;
		padding: 1.5rem 6.5rem;
		color: #fff;
		font-family: @forth-banner-font;
		font-size: 2rem;
		font-weight: 500;
		text-transform: uppercase;
		&.deliverTitle {
			padding-left: 0rem;
			padding-right: 0rem;
		}
		.viewText {
			position: relative;
			display: inline-block;
			padding: 10px;
			color: #fff;
			overflow: hidden;
			transition: 0.5s;
			font-size: 1.5rem;
			margin-bottom: -22px;
			top: -8px;
			span {
				position: absolute;
				display: block;
			}
			span:nth-child(1) {
				top: 0;
				left: -100%;
				width: 100%;
				height: 2px;
				background: linear-gradient(90deg, transparent, #fff);
				animation: btn-anim1 1s linear infinite;
			}
			@keyframes btn-anim1 {
				0% {
					left: -100%;
				}
				50%,
				100% {
					left: 100%;
				}
			}
			span:nth-child(2) {
				top: -100%;
				right: 0;
				width: 2px;
				height: 100%;
				background: linear-gradient(180deg, transparent, #fff);
				animation: btn-anim2 1s linear infinite;
				animation-delay: 0.25s;
			}
			@keyframes btn-anim2 {
				0% {
					top: -100%;
				}
				50%,
				100% {
					top: 100%;
				}
			}
			span:nth-child(3) {
				bottom: 0;
				right: -100%;
				width: 100%;
				height: 2px;
				background: linear-gradient(270deg, transparent, #fff);
				animation: btn-anim3 1s linear infinite;
				animation-delay: 0.5s;
			}
			@keyframes btn-anim3 {
				0% {
					right: -100%;
				}
				50%,
				100% {
					right: 100%;
				}
			}
			span:nth-child(4) {
				bottom: -100%;
				left: 0;
				width: 2px;
				height: 100%;
				background: linear-gradient(360deg, transparent, #fff);
				animation: btn-anim4 1s linear infinite;
				animation-delay: 0.75s;
			}
			@keyframes btn-anim4 {
				0% {
					bottom: -100%;
				}
				50%,
				100% {
					bottom: 100%;
				}
			}
			&:hover {
				background-color: #fff;
				color: #000;
				border-radius: 5px;
				box-shadow: 0 0 5px #fff, 0 0 20px #fff, 0 0 40px #fff,
					0 0 80px #fff;
			}
		}
		@media screen and (max-width: 1198px) {
			padding: 1.5rem 3rem;
			font-size: 20px;
		}

		@media screen and (max-width: 800px) {
			font-size: 1.8rem;
		}
		@media screen and (max-width: 480px) {
			padding: 1.5rem 2rem;
			font-size: 1.4rem;
		}
	}
	.tableDetails {
		.ant-row {
			margin-left: 0 !important;
			margin-right: 0 !important;

			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
			&:last-child {
				border-bottom: 0;
			}
			.ant-btn-sm {
				letter-spacing: 2px;
				padding: 0.8rem 1.2rem;
				font-size: 1.1rem;
			}
			.ant-col {
				padding: 8px 10px;
				border-right: 1px solid rgba(0, 0, 0, 0.2);
				font-size: 1.6rem;
				font-family: @forth-banner-font;
				width: 14.28%;
				&:first-child {
					text-transform: none;
					font-weight: 400;
				}
				&:last-child {
					font-weight: 500;
					border-right: 0;
					word-wrap: break-word;
					color: @text-color;

					.commonClr {
						color: @primary-color;
					}
				}
				@media screen and (max-width: 600px) {
					//	font-size: 1.1rem;
					//word-break: break-all;
					word-wrap: break-word;
				}
			}
		}
		.bgColorBlack {
			background-color: #000000;
		}
	}
	.nodata {
		padding: 15px 10px;
		font-family: @forth-banner-font;
		font-size: 1.6rem;
		font-weight: 500;
		color: #494949;
		text-align: center;
		text-transform: none !important;
	}
	.timeLine {
		margin: 4rem 0px;
		border: 1px solid rgba(0, 0, 0, 0.2);
		width: 100%;
		h4 {
			background-color: rgba(@primary-color, 0.7);
			//background: linear-gradient(180deg, #ff86cd 0%, #ed008c 100%);
			padding: 1.5rem;
			color: #fff;
			font-family: @forth-banner-font;
			font-size: 2rem;
			font-weight: 500;
			text-transform: capitalize;
			@media screen and (max-width: 800px) {
				font-size: 1.8rem;
			}
			@media screen and (max-width: 480px) {
				font-size: 1.4rem;
			}
		}
		.ant-timeline {
			padding: 2rem 3rem;
			.ant-timeline-item {
				padding: 4rem 0;
				@media screen and (max-width: 480px) {
					padding: 6.5rem 0;
				}
			}
			@media screen and (max-width: 600px) {
				padding: 2rem 1rem;
			}
		}
		.ant-timeline-item {
			font-family: @forth-banner-font;
			font-size: 1.6rem;
			font-weight: 500;
			.ant-timeline-item-label {
				width: 140px;
				padding-right: 22px;
				// width: calc(15% - 12px);
				color: #000;
				text-align: center;
				@media screen and (max-width: 800px) {
					width: 100px;
					font-size: 14px;
					padding-right: 10px;
				}
				@media screen and (max-width: 600px) {
					font-size: 13px;
				}
				// @media screen and (max-width: 480px) {
				// 	width: calc(21% - 12px);
				// }
			}
		}
		.ant-timeline-item-tail {
			left: 145px;
			@media screen and (max-width: 800px) {
				left: 95px;
			}
			// @media screen and (max-width: 520px) {
			// 	left: 24%;
			// }
			// @media screen and (max-width: 400px) {
			// 	left: 27%;
			// }
			// @media screen and (max-width: 350px) {
			// 	left: 30%;
			// }
		}
		.ant-timeline-item-head {
			&.ant-timeline-item-head-blue {
				left: 145px;
				top: 0;
				@media screen and (max-width: 800px) {
					left: 95px;
				}
				// @media screen and (max-width: 520px) {
				// 	left: 24%;
				// }
				// @media screen and (max-width: 400px) {
				// 	left: 27%;
				// }
				// @media screen and (max-width: 350px) {
				// 	left: 30%;
				// }
			}
		}
		.ant-timeline-item-content {
			font-family: @forth-banner-font;
			font-size: 1.6rem;
			left: 130px !important;
			width: calc(85% - 14px) !important;
			font-weight: 300;
			position: absolute;
			word-wrap: break-word;
			padding-left: 10px;
			@media screen and (max-width: 800px) {
				//font-size: 14px;
				padding-left: 10px;
				padding-right: 20px;
				left: 80px !important;
				word-break: break-all;
				width: calc(85% - 40px) !important;
				min-height: auto;
				font-size: 14px;
			}
			// @media screen and (max-width: 600px) {
			// 	font-size: 13px;
			// }
		}
	}
	.ant-col-12 {
		@media screen and (max-width: 800px) {
			max-width: 100%;
			flex: 0 0 100%;
			margin: 0rem 0 1rem;
		}
	}
	.trackConsignmentTitle{
		@media screen and (max-width: 800px) {
			grid-gap: 10px;
			margin-bottom: 10px;
		}
	}
	@media screen and (max-width: 800px) {
		padding-top: 6rem;
	}
}
// .ant-image-preview-operations-operation:not(:first-child) {
// 	display: none;
// }
.trackingError {
	width: 100%;
	border: 1px solid rgba(0, 0, 0, 0.75);
	margin: 0 0 4rem 0px;
	margin-top: 0;
	border-radius: 0.5rem;
	> div {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.trackblcHeader {
		border-radius: 0.5rem 0.5rem 0 0;
		background: rgba(0, 0, 0, 0.75);
		padding: 1.5rem;
		//width: fit-content;
		margin-bottom: 0;
		display: flex;
		align-items: flex-start;

		h4 {
			color: @white;
			text-align: left;
			font-family: @primary-font;
			font-size: 2.2rem;
			letter-spacing: 1px;
			margin-bottom: 0px;
			padding-right: 1rem;
			span {
				margin-left: 5px;
			}
			// span {
			// 	display: block;
			// }
			// @media screen and (max-width: 1200px) {
			// 	font-size: 1.8rem;
			// }
			@media screen and (max-width: 800px) {
				font-size: 1.6rem;
				display: flex;
				span {
					display: block;
					word-wrap: break-word;
				}
			}

			// @media screen and (max-width: 600px) {
			// 	font-size: 1.2rem;
			// }
			@media screen and (max-width: 480px) {
				flex-direction: column;
			}
		}
	}
	.errorPera {
		margin-left: 12px;
		text-align: center;
		width: 100%;
		color: @primary-color;
	}
}

// print & image
.printImgCol {
	display: flex;
	align-items: center;
	justify-content: center;

	.printBtn {
		background-color: transparent;
		border: 1px solid @primary-color;
		padding: 5px 8px;
		display: flex;
		align-items: center;
		margin-left: 10px;
		transition: all 0.5s linear;

		svg {
			height: 25px;
			width: 25px;

			path {
				fill: @primary-color;
			}
		}

		&:hover {
			border: 1px solid black;

			svg {
				path {
					fill: black;
				}
			}
		}
	}
	.ant-image {
		margin-left: 10px;
		img {
			border-radius: 10px;
			width: 37px;
		}
	}
}

.tableDetails {
	margin-bottom: 50px;
	.nodata {
		border: 1px solid #000000;
		border-right: 1px solid !important;
		border-top: none;
	}
	table {
		border: 1px solid #231f20;
		border-collapse: collapse;
		margin: 0;
		padding: 0;
		width: 100%;
		table-layout: auto;
		font-family: @forth-banner-font;
		tr {
			background-color: #f8f8f8;
			border: 1px solid #231f20;
			th {
				padding: 18px 8px;
				text-align: center;
				font-size: 14px;
				font-weight: 500;
				color: #000;
				line-height: 1.2;
				text-transform: uppercase;
				background-color: rgba(199, 215, 225, 0.5);
				border-right: 1px solid #231f20;
				&:last-child {
					border-right: 0px;
				}
			}
		}
		td {
			padding: 18px 8px;
			text-align: center;
			font-size: 14px;
			font-weight: 400;
			color: #000;
			line-height: 1.2;
			border-right: 1px solid #231f20;
			&:last-child {
				border-right: 0px;
			}
		}
	}

	@media screen and (max-width: 991px) {
		margin-bottom: 25px;
	}
	@media screen and (max-width: 991px) {
		table {
			border: 0;
		}

		table caption {
			font-size: 1.3em;
		}

		table thead {
			border: none;
			clip: rect(0 0 0 0);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
		}

		table tr {
			border-bottom: 3px solid #ddd;
			display: block;
			margin-bottom: 0.625em;
		}

		table td {
			border-bottom: 1px solid #ddd;
			display: block;
			text-align: right;
			position: relative;
			width: 100%;
			padding-left: 160px;
			border-right: 0px;
			line-height: 1.4;
		}

		table td::before {
			content: attr(data-label);
			float: left;
			font-weight: 500;
			text-transform: uppercase;
			position: absolute;
			left: 10px;
			top: 20px;
		}

		table td:last-child {
			border-bottom: 0;
			display: flex;
			justify-content: flex-end;
			width: 100% !important;
		}
	}

}
