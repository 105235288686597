.clientContainer {
	padding: 13rem 0;

	@media screen and (max-width:767px) {
		padding: 5rem 0;
	}

	.clientSlider {

		//margin-right: 3rem;
		.slick-slider {
			padding-bottom: 10px;
		}

		.clientContent {
			.ant-card {
				margin: 1.5rem 1.5rem;
				border: none;
				box-shadow: 0px 0px 2rem 0px rgba(0, 0, 0, 0.08);

				.ant-card-body {
					.imgWrap {
						border-radius: 20px;
						background-color: #fff;
						width: 100%;
						height: 100%;
						max-height: 70px;
						/* padding: 15px 18px; */
						position: relative;
						display: flex;
						align-items: center;
						justify-content: center;

						img {
							display: block;
							width: auto;
							height: 100%;
							max-height: 100px;
							object-fit: cover;
							@media screen and(max-width:991px) {
								max-height: 70px;
							}
							@media screen and(max-width:800px) {
								max-height: 50px;
							}
						}
					}

					// img {
					// 	width: 100%;
					// 	height: auto;
					// }

					padding: 2.5rem 2rem;

					@media screen and(max-width:1200px) {
						padding: 2rem 3rem;
					}

					@media screen and(max-width:1024px) {
						padding: 1.5rem 2rem;
					}

					@media screen and(max-width:800px) {
						padding: 2rem 1.5rem;
					}

				}
			}
		}

		.slick-slider {
			.slick-prev {
				left: 2rem;
				top: 100%;
				width: 30px;
				.transition(0.50s, linear);

				&:hover {
					path {
						fill: @primary-color;
					}
				}

			}

			.slick-next {
				right: 2rem;
				top: 100%;
				width: 30px;
				.transition(0.50s, linear);

				&:hover {
					path {
						fill: @primary-color;
					}
				}

			}

		}

		.slick-dots {
			// left: -2%;
			position: relative;
			max-width: 25rem;
			margin: 0 auto;
			bottom: -1.5rem;

			li {
				button {
					&::before {
						font-size: 0;
						line-height: 0;
						display: block;
						width: 2rem;
						height: 0.3rem;
						cursor: pointer;
						color: transparent;
						border-radius: 1rem;
						outline: none;
						background: #cccccc;
						margin-right: 2.8rem;
						margin-top: 1.1rem;
					}
				}

				&.slick-active {
					button {
						&::before {
							background-color: @primary-color;
						}
					}
				}

				@media screen and(max-width:600px) {

					height: 30px;
				}
			}

			&::after {
				position: absolute;
				content: "";
				height: 1.1rem;
				width: 1.1rem;
				border: 1px solid @primary-color;
				border-radius: 50%;
				top: 50%;
				right: 5rem;

				@media screen and (max-width:390px) {
					right: 3rem;
				}
			}

			&::before {
				position: absolute;
				content: "";
				height: 1.1rem;
				width: 1.1rem;
				border: 1px solid @primary-color;
				border-radius: 50%;
				top: 50%;
				left: 5rem;

				@media screen and (max-width:390px) {
					left: 3rem;
				}
			}

			@media screen and(max-width:600px) {
				bottom: -15px;
				line-height: 1.5;
			}
		}

		@media screen and (max-width:767px) {
			margin-bottom: 3rem;
		}
	}

	.clientContent {
		.boxImg {
			position: absolute;
			top: 0;
			right: 0;
		}

		.headingSpan {
			&:before {
				right: -10.2rem;

				@media screen and (max-width: 1024px) {
					right: -8.4rem;
				}

				@media screen and (max-width: 600px) {
					right: -7.4rem;
				}

				@media screen and (max-width: 480px) {
					right: -6.3rem;
				}
			}
		}
	}

	.aboutClientSlider {
		img {
			height: auto;
			width: 100%;
		}
	}
}

.formRight {
	form {
		.ant-form-item-control {
			.ant-form-item-explain-error {
				text-align: left;
				margin-left: 30px;

				@media screen and (max-width:600px) {
					margin-left: 0px;
				}
			}
		}
	}

	.ant-picker {
		@media screen and (max-width:600px) {
			width: 100%;
		}
	}
}

.locationList {

	.ant-select {
		box-shadow: none !important;
		width: 350px;
		max-width: 100%;

		&.ant-select-focused {
			box-shadow: none !important;
		}

		.ant-select-selection-search {
			.ant-select-selection-search-input {
				padding-right: 0 !important;
			}
		}

		.ant-select-selection-placeholder {
			padding-left: 10px;
			text-align: left;
			line-height: 2.8 !important;
			font-size: 1.7rem !important;
			// @media screen and (max-width:1200px) {
			// 	line-height:3.5 !important;
			// }
		}

		.ant-select-selection-item {
			text-align: left;
		}

		@media screen and (max-width:600px) {
			width: 100%;
		}
	}

	.ant-form-item-control {
		.ant-form-item-explain-error {
			text-align: left;
			margin-left: 30px;

			@media screen and (max-width:600px) {
				margin-left: 0px;
			}
		}

	}

	@media screen and (max-width:600px) {
		width: 100%;
	}
}