.Network {
	padding: 10rem 0;
	background: @bg-grey-color;
	box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);

	@media screen and (max-width: 767px) {
		padding: 5rem 0px 7rem;
	}

	.networkContent {
		display: flex;
		justify-content: center;
		flex-direction: column;
		// align-items: center;
	}

	.heading {
		h1 {
			font-size: 6rem;

			@media screen and (max-width: 1400px) {
				font-size: 5.3rem;
			}

			@media screen and (max-width: 1200px) {
				font-size: 4rem;
			}

			@media screen and (max-width: 1024px) {
				font-size: 3.9rem;
			}

			@media screen and (max-width: 991px) {
				font-size: 3.3rem;
			}

			@media screen and (max-width: 480px) {
				font-size: 2.4rem;
			}

			@media screen and (max-width: 320px) {
				font-size: 2.2rem;
			}
		}
	}

	.networkBanner {
		height: auto;

		img {
			height: 100%;
			width: 100%;
		}

		@media screen and (max-width:767px) {
			img {
				width: 100%
			}
		}
	}
}

.networkMap {
	padding: 10rem 0;

	@media screen and (max-width:767px) {
		padding: 5rem 0 10rem;
	}

	.mapRow {
		position: relative;

		.leftPera {
			margin-right: 3rem;

			@media screen and(max-width:768px) {
				margin-right: 1rem;
			}

			@media screen and(max-width:480px) {
				margin-right: 0px;
			}

			p {
				@media screen and(max-width:480px) {
					margin-top: 0px;
					margin-bottom: 0px;
				}
			}
		}

		// &::after {
		// 	position: absolute;
		// 	background-color: rgba(0, 0, 0, 0.2);
		// 	content: "";
		// 	height: 55%;
		// 	max-height: 100%;
		// 	width: 1px;
		// 	margin: 0 auto;
		// 	left: 0;
		// 	right: 0;
		// 	top: 3rem;
		// 	@media screen and(max-width:600px) {
		// 		display: none;
		// 	}
		// }
	}

	.rightPera {
		margin-left: 3rem;

		@media screen and(max-width:768px) {
			margin-left: 1rem;
		}

		@media screen and(max-width:480px) {
			margin-left: 0px;
		}
	}

	.formBg {
		margin: 6rem 0px 4rem 0px;
		background: #f7f7ff;
		box-shadow: 0px 0.2rem 1rem 0px rgba(0, 0, 0, 0.15);
		position: relative;
		width: 100%;
		padding: 5.3rem 6rem;
		justify-content: center;
		isolation: isolate;

		&:before {
			background: url("../../../images/tabone.svg") no-repeat top left;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 185px;
			background-size: contain;
			z-index: -1;
		}

		&::after {
			transform: rotate(180deg);
			right: 0;
			bottom: 0;
			left: auto;
			content: "";
			background: url("../../../images/tabone.svg") no-repeat top left;
			position: absolute;
			height: 100%;
			width: 185px;
			background-size: contain;
			z-index: -1;
		}

		form {
			display: flex;
			align-items: center;
			justify-content: space-evenly;

			label {
				font-family: @secondary-font;
				color: @secondary-color;
				font-size: 2.2rem;
				font-weight: 300;
			}

			@media screen and(max-width:991px) {
				flex-wrap: wrap;
			}
		}

		.inputField {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: nowrap;
			flex: 0 1 1;
			width: 100%;

			.ant-form-item {
				width: 100%;
				margin: 0;
				padding: 0;
				height: 100%;

				.ant-input {
					height: 48px;
					border-radius: 40px;
					font-size: 1.9rem;

					@media screen and(max-width:650px) {
						font-size: 1.5rem;
					}

					@media screen and(max-width:480px) {
						font-size: 1.3rem;
					}
				}

				.ant-form-item-control-input-content {
					.ant-select {
						.ant-select-selector {
							height: 48px;
							border-radius: 40px;

							.ant-select-selection-search {
								.ant-select-selection-search-input {
									height: 48px;
									padding-left: 10px;
									padding-right: 75px;
								}
							}

							.ant-select-selection-placeholder {
								height: 48px;
								padding-left: 10px;
								line-height: 2.3;
								color: rgba(0, 0, 0, 0.2);
								font-family: @secondary-font;
								font-size: 2rem;

								@media screen and(max-width:1400px) {
									font-size: 1.7rem;
									line-height: 2.5;
								}

								@media screen and(max-width:1200px) {
									font-size: 1.5rem;
									line-height: 2.5;
								}

								@media screen and(max-width:800px) {
									font-size: 1.2rem;
									line-height: 2.5;
								}
							}

							.ant-select-selection-item {
								line-height: 3.5;
								padding-left: 10px;
							}
						}

						.ant-select-clear {
							right: 9.5rem;
						}
					}
				}
			}

			label {
				font-family: @secondary-font;
				font-size: 2rem;
				color: @secondary-color;
				white-space: nowrap;
				margin-right: 20px;

				@media screen and(max-width:1024px) {
					font-size: 1.8rem;
				}

				@media screen and(max-width:600px) {
					font-size: 1.5rem;
				}

				@media screen and(max-width:480px) {
					font-size: 1.3rem;
				}
			}

			&.ant-input-group {
				background-color: transparent;
				// border:1px solid rgba(0,0,0,0.2);
				height: 48px;
				border-radius: 40px;

				.searchBtn {
					background: @primary-color;
					position: absolute;
					right: 0;
					border: none;
					width: 90px;
					max-width: 90px;
					border-bottom-left-radius: 40px;
					height: 100%;
					border-top-right-radius: 25px;
					border-bottom-right-radius: 25px;
				}
			}

			@media screen and(max-width:991px) {
				margin: 1rem 0px;

				.ant-input-group {
					margin: 0;
				}
			}

			@media screen and(max-width:520px) {
				display: block;
			}
		}

		@media screen and(max-width:1073px) {
			padding: 5.3rem 3rem;
		}

		@media screen and(max-width:991px) {
			padding: 3.8rem 3rem;
		}

		@media screen and(max-width:983px) {
			padding: 0.2rem 3rem;
			margin: 2rem 0 0;
		}

		.pincodeForm {
			margin: 0px 100px 0px 0px;

			@media screen and(max-width:1400px) {
				margin: 0px 0px 0px 0px;
			}
		}

		.locationForm {
			margin: 0px 0px 0px 100px;

			@media screen and(max-width:1400px) {
				margin: 0px 0px 0px 0px;
			}
		}
	}

	.ant-col {
		padding-left: 0px;
		padding-right: 0px;

		p {
			font-size: 2.1rem;

			@media screen and(max-width:1600px) {
				font-size: 2rem;
			}

			@media screen and(max-width:1600px) {
				font-size: 1.8rem;
			}

			@media screen and(max-width:1200px) {
				font-size: 1.6rem;
			}

			// @media screen and(max-width:1024px) {
			// 	font-size: 1.4rem;
			// }
			// @media screen and(max-width:600px) {
			// 	font-size: 1.3rem;
			// }
		}
	}

	.centerDeatilsTable {
		.centerName {
			cursor: pointer;
			color: @primary-color;
			text-decoration: underline;
			word-wrap: break-word;
			word-break: break-all;
		}
	}

	table {
		border: 1px solid rgba(0, 0, 0, 0.2);
		width: 100%;
		table-layout: fixed;

		@media screen and (max-width: 650px) {
			border: 0px solid rgba(@secondary-color, 0.2);
		}

		thead {
			position: relative;
			clip: rect(1px, 1px, 1px, 1px);

			tr {
				text-align: left;
				border-bottom: 1px solid rgba(0, 0, 0, 0.2);

				th {
					border-right: 1px solid rgba(0, 0, 0, 0.2);
					padding: 14px;
					background-color: #f7f7ff;
					color: #000;
					font-family: @secondary-font;
					font-size: 2rem;
					font-weight: 400;

					@media screen and(max-width:800px) {
						font-size: 1.5rem;
					}

					@media screen and(max-width:480px) {
						font-size: 1.2rem;
						padding: 1.5rem 0.5rem;
					}

					@media screen and (max-width: 650px) {
						display: block;
					}
				}

				@media screen and (max-width: 650px) {
					display: block;
				}
			}

			@media screen and (max-width: 650px) {
				position: absolute;
				overflow: hidden;
				clip: rect(1px, 1px, 1px, 1px);
			}
		}

		tbody {
			tr {
				border-bottom: 1px solid rgba(0, 0, 0, 0.2);

				td {
					padding: 14px;
					border-right: 1px solid rgba(0, 0, 0, 0.2);
					color: #000;
					font-family: @forth-banner-font;
					font-size: 1.8rem;
					font-weight: 300;
					word-wrap: break-word;

					@media screen and(max-width:800px) {
						font-size: 1.5rem;
					}

					@media screen and (max-width: 650px) {
						display: flex;
						justify-content: space-between;

						padding: 1rem 0.7rem;
						border-right: 0;

						&::before {
							content: attr(data-label);
						}

						span {
							text-align: right;
						}

						&:nth-child(even) {
							background-color: rgba(@secondary-color, 0.04);
						}
					}

					@media screen and (max-width: 600px) {
						font-size: 1.2rem;

					}
				}

				@media screen and (max-width: 650px) {
					border: 1px solid rgba(@secondary-color, 0.2);
					margin-bottom: 1.5rem;
					display: block;
					border-radius: 7px;
				}
			}
		}

		.recordCol {
			border-right: none;
			text-align: center;

			@media screen and (max-width:600px) {
				justify-content: center;
			}
		}
	}

	.paginationData {
		width: 100%;
		text-align: right;
		margin-top: 25px;

		.ant-pagination {
			.ant-pagination-total-text {
				font-family: @primary-font;
				font-size: 1.4rem;
			}
		}

		.ant-pagination-options {
			display: none;
		}
	}

	.clientDetailHeading {
		margin: 3.5rem 0px 2rem 0px;
		font-size: 2.2rem;
		border-radius: 6rem 0px 11rem 6rem;
		background: #191919;
		padding: 1rem 2.8rem;

		span {
			color: #fff;
			font-weight: 500;
			text-transform: capitalize;
			font-family: @secondary-font;
		}

		@media screen and (max-width: 600px) {
			font-size: 1.8rem;
			padding-left: 2rem;
		}
	}

	.ant-col-12 {
		@media screen and(max-width:600px) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
}

.searchInput {
	margin-right: 0;
	margin-left: 2.4rem;

	.ant-input-search {
		background-color: @white;
		border-radius: 4rem;
		height: 4.2rem;
		box-shadow: 0px 0.2rem 1.5rem 0px rgba(0, 0, 0, 0.21);

		.ant-input-affix-wrapper {
			background-color: transparent;
			border: none !important;
			outline: none;
			box-shadow: none;
			min-height: 4.2rem;
			height: 4.2rem;
			padding: 0px 1.5rem;

			.ant-input {
				background-color: transparent;
				border: none;
				font-size: 1.4rem;
				font-weight: 400;
				border-radius: 2rem;
				font-family: @secondary-font;
				color: @secondary-color;
				height: 4.1rem;

				&::placeholder {
					font-family: @secondary-font;
				}
			}
		}

		// .ant-input-affix-wrapper-focused{
		// 	border-bottom:1px solid @primary-color !important;
		// 	border-left:1px solid @primary-color !important;
		// 	border-top:1px solid @primary-color !important;

		// }
		// .ant-input{
		// 	&:focus{
		// 		box-shadow:none;
		// 		border-bottom:1px solid @primary-color !important;
		// 		border-left:1px solid @primary-color !important;
		// 		border-top:1px solid @primary-color !important;
		// 		// box-shadow: 0 0 0 1px #4a5cfb;
		// 	}
		// 	&:focus ~ span.ant-input-group-addon{
		// 		border:1px solid @primary-color;
		// 	}
		// }

		.ant-input-group-addon {
			background-color: transparent;
			border: none;

			.ant-btn {
				background-color: @primary-color;
				box-shadow: none;
				padding: 1rem 2rem;
				width: auto;
				height: 4.2rem;
				border-radius: 4rem 4rem 4rem 0rem !important;

				.anticon {
					font-size: 2rem;
					font-weight: 700;
					color: @white;
				}
			}
		}
	}

	.ant-input-wrapper {
		&:focus {
			border: 1px solid blue;
		}
	}

	@media screen and(max-width:520px) {
		margin-left: 0px;
	}
}

.centerDetailsModal {
	width: 550px !important;
	.ant-modal-header {
		background-color: @primary-color;

		.ant-modal-title {
			color: #fff;
			font-family: @primary-font;
			font-size: 1.9rem;

			@media screen and (max-width: 600px) {
				font-size: 1.6rem;
			}
		}
	}

	.ant-modal-body {
		padding: 0px 0px;

		table {
			tr {

				td,
				th {
					font-family: @secondary-font;
					font-size: 1.6rem;
					color: @secondary-color;
					width: 70%;
					padding: 15px;
					text-align: left;
					word-wrap: break-word;
					font-weight: normal;

					&:first-child {
						text-transform: capitalize;
						color: #000;
						vertical-align: top;
						width: 30%;
					}
					a{
						&.locationMap{color: @primary-color; text-decoration: underline;}
					}
				}

				@media screen and (max-width: 600px) {

					td,
					th {
						font-size: 15px;
						color: #555;
						display: flex;
						// flex-direction: column;
						border: none;
						padding: 0 10px 5px;
						width: 100%;
						margin-bottom: 0px;

						&:first-child {
							width: 100%;
							font-size: 16px;
							margin-bottom: 0;
							font-weight: normal;
						}
						&.addressView{
							flex-direction: column;
						}
					}

					th {
						padding: 5px 10px 0;
					}
				}

				@media screen and (max-width: 600px) {
					&:nth-child(even) {
						background-color: rgba(@secondary-color, 0.04);
					}
				}
			}

			.copyText {
				font-size: 1.2rem;
				margin-top: 0px;
				margin-bottom: 0px;
			}

			.copyBtn {
				border: none;
				padding: 0px 0px;
				margin: 0px 3px;
				background:transparent;
				// height: 25px;
				// width: 25px;
				// border-radius: 50%;
				vertical-align: inherit;
				svg{
					margin: 2px 3px 0px 3px;
					path{
						fill:@primary-color;
					}
				}
			}
		}

		@media screen and (max-width: 600px) {
			padding: 14px;
		}
	}

	@media screen and (max-width: 600px) {
		display: block;
		width: 100%;
	}

	.ant-modal-content {
		.ant-modal-close {
			color: @white;
		}
	}
}