// color
@primary-color: #ed008c;
@secondary-color: #000;
@grey-color: #d9d9d9;
@white-color: #fff;
@banner-bg-color: #f4f7fc;
@bg-grey-color: #f9f9f9;
@text-input-color: #777;
@yellow-color: #ef9a57;
@danger_color:#ff3131;
@input_label_color: #444444;
.bottomLinear {
	background-image: linear-gradient(
		181deg,
		rgba(255, 255, 255, 1) 82%,
		rgba(244, 247, 252, 1) 100%
	);
}
.topLinear {
	background-image: linear-gradient(
		0deg,
		rgb(244, 248, 252) 82%,
		rgba(244, 247, 252, 1) 100%
	);
}

// fonts
@primary-font: "Exo", sans-serif;
@secondary-font: "Jost", sans-serif;
@mild-font: "Saira", sans-serif;
@forth-banner-font: "Poppins", sans-serif;
.headerFonts {
	font-family: @primary-font;
	font-weight: 800;
	color: @secondary-color;
}

.peraFonts {
	font-family: @secondary-font;
	font-weight: 300;
	color: @secondary-color;
}

.btnFonts {
	font-family: @primary-font;
	font-weight: 600;
	font-size: 1.8rem;
	color: @white;
	text-transform: uppercase;
}
.boldFonts {
	font-family: @primary-font;
	font-weight: 600;
}

@gutter: 2.4rem;
@text-color: #494949;
@heading-color: @text-color;
@border-radius-base: 0.4rem;

// Main Header Variables
@layout-body-background: @white;
@layout-content-background: #f0f2f5;
@layout-header-background: @white;
@layout-header-height: 10rem;
@layout-header-padding: 0 0;
@layout-header-color: @text-color;
@layout-sidebar-background: #263238;

// Main Header Logo Variables
@logo-height: 5.6rem;
@logo-text-size: 1.8rem;
@logo-space: 1rem;

// Section Variables
@section-padding: 5rem;

// mixins

.transition(@time,@effect) {
	transition: all @time @effect;
}



