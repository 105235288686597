* {
	box-sizing: border-box;

	&::after,
	&::before {
		box-sizing: border-box;
	}

	&::selection {
		background: @primary-color;
		color: @white-color;
	}
}

html,
body {
	font-size: 62.5%;
	// @media screen and (max-width: 600px) {
	// 	font-size: 80%;
	// }
}

html {
	padding: 0px;
	margin: 0px;
	scroll-behavior: smooth;

	body {
		color: @secondary-color;
		font-family: @primary-font;
		font-weight: normal;
		margin: 0px;
		padding: 0px;
		width: 100%;
		line-height: normal;
	}

	p {
		font-family: @secondary-font;
		font-size: 2.2rem;
		font-weight: 300;
		margin-top: 15px;
		margin-bottom: 15px;
		// @media screen and (max-width: 1400px) {
		// 	font-size: 2.1rem;
		// }

		// @media screen and (max-width: 1390px) {
		// 	font-size: 2rem;
		// }

		@media screen and (max-width: 1200px) {
			//font-size: 1.8rem;
		}

		@media screen and (max-width: 1024px) {
			//font-size: 1.6rem;
			padding-top: 0px;
		}

		@media screen and (max-width: 800px) {
			//font-size: 1.5rem;
			padding-top: 0px;
		}

		@media screen and (max-width: 480px) {
			padding-top: 0px;
			margin-top: 5px;
			margin-bottom: 5px;
		}
	}

	ul,
	li {
		list-style: none;
	}
}

.backTopIcon {
	position: fixed;
	border-radius: 100%;
	bottom: 5.5rem;
	right: 5rem;
	height: 40px;
	border: none;
	width: 40px;
	cursor: pointer;
	background-color: transparent;
	z-index: 999;
	padding: 0px 0px;

	@media screen and (max-width: 600px) {
		right: 7rem;
	}

	@media screen and (max-width: 420px) {
		right: 4rem;
		bottom: 1.5rem;

		svg {
			height: 5rem;
			width: 5rem;
		}
	}
}

.imgTop {
	@media screen and (max-width: 600px) {
		margin-top: 30px;
	}
}

.heading {
	h1 {
		font-size: 7rem;
		margin-bottom: 0.3rem;
		color: @secondary-color;
		font-family: @forth-banner-font;
		font-weight: 300;
		line-height: 1.3;

		strong {
			font-weight: 800;
		}

		b {
			font-family: @mild-font;
			color: @secondary-color;
			font-weight: 500;
		}

		@media screen and (max-width: 1600px) {
			font-size: 6rem;
		}

		@media screen and (max-width: 1400px) {
			font-size: 5.5rem;
		}

		@media screen and (max-width: 1200px) {
			font-size: 4.2rem;
		}

		@media screen and (max-width: 1024px) {
			font-size: 4rem;
		}

		@media screen and (max-width: 991px) {
			font-size: 3.5rem;
		}

		@media screen and (max-width: 480px) {
			font-size: 2.6rem;
		}

		@media screen and (max-width: 320px) {
			font-size: 2.4rem;
		}
	}
}

// img{
// 	@media screen and (max-width:480px) {
// 		margin: 35px 0px;
// 	}
// }
.headingSpan {
	b {
		@media screen and(max-width:1200px) {
			font-size: 2.2rem;
		}

		@media screen and(max-width:1024px) {
			font-size: 1.7rem;
		}

		@media screen and(max-width:800px) {
			font-size: 1.5rem;
		}
	}
}

.boxImg {
	img {
		@media screen and (max-width: 1400px) {
			height: 110px;
			width: 110px;
		}

		@media screen and (max-width: 1024px) {
			height: 95px;
			width: 95px;
		}

		@media screen and (max-width: 991px) {
			height: 70px;
			width: 70px;
		}

		@media screen and (max-width: 800px) {
			visibility: hidden;
		}
	}
}

.centerBottomImg {
	img {
		@media screen and (max-width: 1400px) {
			height: 100px;
			width: 100px;
		}

		@media screen and (max-width: 1024px) {
			height: 95px;
			width: 95px;
		}

		@media screen and (max-width: 991px) {
			height: 70px;
			width: 70px;
		}

		@media screen and (max-width: 800px) {
			visibility: hidden;
		}
	}
}

.col-100 {
	@media screen and(max-width:800px) {
		flex: 0 0 100%;
		max-width: 100%;
		padding-top: 40px;
	}

	@media screen and(max-width:480px) {
		padding-top: 0px;
	}
}

.ant-btn-primary,
.commanBtn {
	border-radius: 10px;
	background: @secondary-color;
	text-transform: uppercase;
	padding: 8px 30px;
	max-height: 50px;
	height: inherit;
	cursor: pointer;
	letter-spacing: 3px;
	.transition(0.5s, linear);
	.btnFonts();
	border: 1px solid transparent;

	&:hover {
		background-color: transparent;
		color: @primary-color;
		border: 1px solid @primary-color;
	}

	&:focus {
		background: @secondary-color;
		color: @white-color;
		border: 1px solid transparent;
	}

	@media screen and (max-width: 1400px) {
		padding: 8px 25px;
		font-size: 1.7rem;
	}

	@media screen and (max-width: 1200px) {
		padding: 0.8rem 2.2rem;
		font-size: 1.6rem;
	}

	// @media screen and (max-width: 1024px) {
	// 	padding: 0.8rem 2rem;
	// 	font-size: 1.4rem;
	// }
	// @media screen and (max-width: 600px) {
	// 	padding: 0.6rem 1.5rem;
	// 	font-size: 1.2rem;
	// }

	// span {
	// 	@media screen and(max-width:480px) {
	// 		font-size: 1.2rem;
	// 	}
	// }
}

.alignCenter {
	display: flex;
	align-items: center;
	justify-content: center;
}

.col-center {
	display: flex;
	// align-items: center;
}

.ant-input {
	border-radius: 10px;
	border: 1px solid #c0c0c0;
	background: @white;
	height: 5.6rem;
	box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.06);
	color: @text-input-color;
	font-size: 2rem;
	font-family: @secondary-font;
	padding: 1.4rem 3rem;
	letter-spacing: 0.6px;
	font-weight: 500;
}

.headingContent {
	h2 {
		.headerFonts();
		font-size: 6rem;
		margin-bottom: 0px;
		position: relative;

		@media screen and (max-width: 1600px) {
			font-size: 5.2rem;
		}

		@media screen and (max-width: 1420px) {
			font-size: 4.8rem;
		}

		@media screen and (max-width: 1200px) {
			font-size: 3.5rem;
		}

		// @media screen and (max-width: 1024px) {
		// 	font-size: 4rem;
		// }

		@media screen and (max-width: 991px) {
			font-size: 3.3rem;
		}

		// @media screen and (max-width: 800px) {
		// 	font-size: 3.5rem;
		// }

		// @media screen and (max-width: 480px) {
		// 	font-size: 2.2rem;
		// }
	}
}

.readMoreLink {
	.btnFonts();
	font-size: 2rem;
	letter-spacing: 2px;
	color: @secondary-color;
	position: relative;
	.transition(0.5s, linear);

	span {
		padding-left: 0.4rem;
	}

	&::after {
		position: absolute;
		content: "";
		background-color: @secondary-color;
		height: 1px;
		width: 100%;
		bottom: 0.3rem;
		left: 0;
	}

	&:hover {
		color: @primary-color;

		span {
			svg {
				path {
					fill: @primary-color;
				}

				@media screen and (max-width: 480px) {
					width: 1rem;
					height: 1rem;
				}
			}
		}

		&::after {
			background-color: @primary-color;
		}
	}

	@media screen and (max-width: 1400px) {
		font-size: 1.9rem;
	}

	@media screen and (max-width: 1200px) {
		font-size: 1.6rem;
	}

	@media screen and (max-width: 1024px) {
		font-size: 1.5rem;
	}

	@media screen and (max-width: 800px) {
		font-size: 1.4rem;
	}

	@media screen and (max-width: 480px) {
		font-size: 1.3rem;

		svg {
			width: 1rem;
			height: 1rem;
		}
	}
}

.subHeading {
	font-size: 2.6rem;
	letter-spacing: 1.56px;
	color: @secondary-color;
	font-family: @primary-font;
	font-weight: 400;
	// position: relative;
	// padding-bottom: 20px;

	.beforeLine {
		background-color: @secondary-color;
		height: 1px;
		width: 20%;
		position: absolute;
		content: "";
		top: 50%;

		@media screen and(max-width:480px) {
			top: 25%;
		}
	}

	.afterLine {
		background-color: @secondary-color;
		height: 1px;
		width: 20%;
		position: absolute;
		content: "";
		top: 1.2rem;
		left: 12.5rem;

		@media screen and(max-width:1024px) {
			top: 0.8rem;
			left: 10rem;
		}

		@media screen and(max-width:600px) {
			left: 9.2rem;
		}

		@media screen and(max-width:480px) {
			top: 0.4rem;
			left: 7.4rem;
		}
	}

	.headingSpan {
		position: relative;

		&::after {
			position: absolute;
			background-color: @secondary-color;
			height: 1px;
			width: 8rem;
			right: -8.5rem;
			top: 1.8rem;
			max-width: 12rem;
			content: "";

			@media screen and (max-width: 1024px) {
				top: 1.3rem;
			}

			// @media screen and (max-width: 600px) {
			// 	top: 1rem;
			// }

			// @media screen and (max-width: 480px) {
			// 	top: 0.9rem;
			// }
		}

		@media screen and (max-width: 1024px) {
			font-size: 2rem;
		}

		// @media screen and (max-width: 600px) {
		// 	font-size: 1.7rem;
		// }

		// @media screen and (max-width: 480px) {
		// 	font-size: 1.3rem;
		// }

		b {
			@media screen and (max-width: 600px) {
				font-size: 1.7rem;
			}

			@media screen and (max-width: 480px) {
				font-size: 1.4rem;
			}
		}
	}

	.headingBeforeSpan {
		position: relative;
		padding-left: 13.1rem;

		&::before {
			position: absolute;
			background-color: @secondary-color;
			height: 1px;
			width: 12rem;
			// right: -125px;
			left: 0;
			top: 1.8rem;
			max-width: 100%;
			content: "";

			@media screen and (max-width: 1024px) {
				right: -10.6rem;
				top: 1.3rem;
			}

			@media screen and (max-width: 600px) {
				right: -9.3rem;
				top: 1rem;
			}

			@media screen and (max-width: 480px) {
				right: -7.5rem;
				top: 0.9rem;
			}
		}

		@media screen and (max-width: 1024px) {
			font-size: 2rem;
		}

		@media screen and (max-width: 600px) {
			font-size: 1.7rem;
		}

		@media screen and (max-width: 480px) {
			font-size: 1.4rem;
		}

		b {
			@media screen and (max-width: 600px) {
				font-size: 1.7rem;
			}

			@media screen and (max-width: 480px) {
				font-size: 1.4rem;
			}
		}
	}

	@media screen and(max-width:480px) {
		margin-bottom: 0.5rem;
	}
}

.pera {
	max-width: 100%;
	width: 74rem;

	p {
		color: @secondary-color;
		font-size: 2.4rem;
		.peraFonts();
	}
}

.main__page__wrapper {
	&.has__header {
		.main__page__content {
			padding-top: 100px;
		}
	}

	&.has__appsidebar {
		.main__page__content {
			display: flex;
			flex-direction: column;
			background-color: @layout-content-background;
			height: 100vh;
		}
	}

	.main__app__content {
		display: flex;
		flex-direction: column;

		.app__container {
			display: flex;
			flex-direction: column;
			padding-left: @gutter;
			padding-right: @gutter;
			padding-bottom: @gutter;

			&__card {
				height: 100%;
			}
		}
	}
}

.ag-grid-wrapper {
	height: 100%;
	min-height: 400px;
}

.logo__wrapper {
	//height: @logo-height;
	display: flex;
	align-items: center;
	max-width: 28.5rem;

	img {
		max-height: 100%;
		max-width: 100%;

		&+span {
			margin-left: @logo-space;
		}
	}

	span {
		white-space: nowrap;
		font-size: @logo-text-size;
		font-weight: bold;
		color: @text-color;
	}

	@media screen and (max-width: 1400px) {
		max-width: 26.5rem;
	}

	@media screen and (max-width: 1199px) {
		max-width: 20rem;
	}
}

.fullscreen__spinner__wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 999999;
	background-color: rgba(255, 255, 255, 0.95);
}

// .section {
// 	padding: 10rem 0px;

// 	@media screen and(max-width:1390px) {
// 		padding: 9rem 0px;
// 	}

// 	@media screen and(max-width:1200px) {
// 		padding: 8rem 0px;
// 	}

// 	@media screen and(max-width:991px) {
// 		padding: 6rem 0px;
// 	}

// 	@media screen and (max-width: 600px) {
// 		padding: 3.3rem 0px;
// 	}
// }

.pageNavigation {
	position: absolute;
	bottom: -10rem;
	cursor: pointer;

	.ant-breadcrumb {
		background-color: @secondary-color;
		border-radius: 20px 20px 0px 0px;
		padding: 1rem 3.5rem !important;

		li {
			font-family: @primary-font;
			font-size: 2.1rem;
			font-weight: 500;

			.ant-breadcrumb-link {
				a {
					color: #fff;
					text-transform: uppercase;
				}
			}

			.ant-breadcrumb-separator {
				color: @white;
			}

			&:last-child {
				a {
					color: @primary-color;
					text-transform: uppercase;
				}
			}

			@media screen and (max-width: 1400px) {
				font-size: 1.7rem;
			}

			@media screen and (max-width: 768px) {
				font-size: 1.5rem;
			}

			@media screen and (max-width: 600px) {
				font-size: 1.2rem;
			}
		}

		// .ant-page-header-heading {
		// 	.ant-page-header-heading-title {
		// 		color:@primary-color;
		// 		font-family:@primary-font;
		// 		font-size: 2rem;

		// 		font-weight: 500;

		// 		letter-spacing: 3.08px;
		// 		text-transform: uppercase;pageNav
		// 	}
		// }
		@media screen and (max-width: 600px) {
			padding: 0.5px 3rem;
		}
	}

	@media screen and (max-width: 1200px) {
		bottom: -8rem;
	}

	@media screen and (max-width: 991px) {
		bottom: -7.1rem;
	}

	@media screen and (max-width: 800px) {
		bottom: -7.2rem;
	}

	@media screen and (max-width: 768px) {
		bottom: -6.9rem;
	}

	@media screen and (max-width: 600px) {
		bottom: -5.9rem;
	}
}

.section__big__title {
	display: block;
	font-weight: bold;
	font-size: 5.6rem;
	color: @text-color;
}

.section__subtitle {
	display: block;
	font-size: 2.4rem;
	color: @text-color-secondary;
}

.ant-card {
	.card__title {
		font-weight: 600;
	}
}

section.privacyPolicy {
	padding: 5rem 0 0;
	// margin: -9rem auto 0;
}

// Blue Bg
.blue_bg {
	background-color: @banner-bg-color;
	padding: 0rem 0px;
	box-shadow: 0 0 1rem 1rem #f4f7fc;
}

.contentPera {
	p {
		font-size: 2.2rem;

		@media screen and (max-width: 600px) {
			font-size: 1.8rem;
		}
	}
}

// comman track btn
.trackCommanBtn {
	.trackIcon {
		bottom: 50%;
		height: auto;
		right: 28px;
		min-height: unset;
		min-width: unset;
		position: fixed;
		z-index: 91;
		cursor: pointer;

		.trackSvg {
			height: 32px;
			width: 32px;
		}

		@media screen and (max-width:991px) {
			bottom: 40%;
		}
	}

	button {
		background-color: transparent;
		border-radius: 20px 20px 0px 0px;
		color: @white;
		padding: 6px 28px 5px 28px;
		bottom: 50%;
		height: auto;
		right: -90px;
		min-height: unset;
		min-width: unset;
		position: fixed;
		z-index: 90;
		transform: rotate(270deg);
		border-color: @primary-color;

		span {
			font-size: 1.6rem;
			font-family: @forth-banner-font;
			font-weight: 500;
			color: @primary-color;
		}

		&:hover {
			background-color: @primary-color;

			span {
				color: @white;
			}
		}

		@media screen and (max-width:991px) {
			bottom: 40%;
		}
	}

	&:hover {
		.trackIcon {
			.trackSvg {
				circle {
					fill: @white;
					stroke: @primary-color;
				}

				g {
					path {
						fill: @primary-color;
					}
				}
			}
		}
	}
}

// comman Client login
.loginForm {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;

	.loginFormWrap {
		width: 100%;

		.inlineBtn {
			flex-direction: row;
			justify-content: space-between;

			.ant-col {
				padding-left: 0;
				padding-right: 0;

				.commanBtn {
					width: 100%;
				}
			}

			@media screen and (max-width: 480px) {
				flex-direction: column;
				justify-content: center;
				gap: 8px;

				.ant-col {
					max-width: 100%;
				}
			}
		}
	}

	.logo__wrapper {
		max-width: 100%;
		justify-content: center;
	}

	.formTitle {
		text-align: center;
		margin: 2rem 0;

		h2 {
			font-weight: 500;
			font-family: @secondary-font;
		}
	}
}

.clientModal {
	.ant-modal-body {
		padding: 35px;
	}

	&.logoutModal {
		.ant-modal-body {
			padding: 0 35px 35px;

			p {
				text-align: center;
				color: @primary-color;
			}
		}
	}

	.ant-modal-close {
		@media screen and (max-width:480px) {
			top: -8px;
			right: -10px;
		}
	}

	// .ant-input-affix-wrapper {
	// 	.ant-input {
	// 	    padding: 10px 0 12px 0 !important;
	// 	}
	// }
}

.clientLogin {
	.ant-row {
		flex-direction: column;

		&.inlineBtn {
			flex-direction: row;
			justify-content: space-around;
		}

		.ant-form-item-label {
			text-align: left;
		}

		.ant-form-item-control-input {
			.ant-form-item-control-input-content {
				.ant-input {
					height: 42px;
					padding: 0.5rem 1.2rem;

					&::placeholder {
						font-size: 1.6rem;
					}
				}

				.ant-input-affix-wrapper {
					height: 42px;
					border-radius: 10px;
					padding: 0px 11px;

					.ant-input {
						height:  38px;
						background-color: transparent;
						box-shadow: none;
						padding: 0rem;
					}
				}
			}
		}

		.ant-col {
			padding-left: 0px !important;
			padding-right: 0px !important;
		}
	}

	.resetPassBtn {
		display: flex;
		align-items: center;
		justify-content: flex-end !important;

		button {
			border: none;
			padding: 0px 0px;
			width: 30%;

			span {
				font-weight: 500;
				color: @primary-color;
			}
		}
	}

	button {
		width: 100%;
	}

	.backTo {
		margin: 1rem 0;
		text-align: center;

		.backToBtn {
			background-color: transparent;
			padding: 0px 0px;
			border: none;
			outline: none;

			span {
				font-weight: 500;
				font-size: 1.4rem;
			}
		}
	}

	.loginBtn {
		border-radius: 10px;
		background: transparent;
		text-transform: uppercase;
		padding: 8px 30px;
		height: inherit;
		cursor: pointer;
		.transition(0.5s, linear);
		font-family: @primary-font;
		font-weight: 600;
		font-size: 1.7rem;
		letter-spacing: 1.8px;
		text-transform: uppercase;
		color: @secondary-color;
		border: none;
		outline: 0;
		box-shadow: none;

		&:hover {
			background-color: transparent;
			color: @primary-color;
		}

		&:focus,
		&:focus-visible,
		&:visited,
		&:focus-within {
			background-color: transparent;
			color: @primary-color;
			outline: none;
			box-shadow: none;
		}

		@media screen and (max-width: 1600px) {
			padding: 7px 15px;
		}

		@media screen and (max-width: 1300px) {
			padding: 6px 10px;
		}

		@media screen and (max-width: 1200px) {
			padding: 4px 8px;
		}

		span {
			@media screen and (max-width: 1600px) {
				font-size: 1.5rem;
			}

			@media screen and (max-width: 1300px) {
				font-size: 1.4rem;
			}

			@media screen and (max-width: 1200px) {
				font-size: 1.3rem;
			}
		}

		@media screen and (max-width: 991px) {
			text-align: left;
			padding: 10px 28px;

			span {
				font-weight: 600;
				font-size: 1.7rem;
			}
		}
	}

	.ant-form-item-label {
		label {
			font-size: 1.5rem;
			font-family: @primary-font;
			letter-spacing: 1px;
		}
	}

	@media screen and (max-width: 991px) {
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}
}

.thankyouImg {
	img {
		width: 100%;
		height: auto;
	}
}

// Verified Email
.verifyEmailSection {
	padding: 5rem 0;

	.verifyEmailPage {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.ant-row {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;

			.ant-col {
				h2 {
					font-size: 2.5rem;
					padding: 3rem 0 5rem;
					text-align: center;

					span {
						color: @primary-color;
						font-size: 32px;
						margin-top: 10px;
						display: block;
						text-align: center;
					}
				}

				img {
					max-width: 100%;
				}
			}
		}

		@media screen and(max-width:400px) {
			.groupBtn {
				display: flex;
				flex-direction: column;
				width: 100%;

				.ant-space-item {
					width: 100%;

					a {
						width: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}
		}
	}
}

.listComponent {
	.ag-theme-alpine {
		height: 60vh;
		margin: 6rem 0;
	}

	.listHeading {
		font-size: 1.8rem;
		font-weight: 600;
		font-family: @primary-font;
		margin: 3rem 0;
	}
}

// client list

.clientListing {
	h1 {
		font-size: 2.2rem;
		font-weight: 600;
		font-family: @primary-font;
		margin: 2rem 0;
	}

	.clientHeader {
		margin: 3rem 0;
		// border: 1px solid rgba(0, 0, 0, 0.2);
		// width: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 2rem;

		.addressDetails {
			// max-width: 320px;
			text-align: center;
		}

		.userName {
			span {
				font-family: @secondary-font;
				color: @secondary-color;
				font-size: 1.8rem;
				font-weight: 300;

				@media screen and(max-width:1400px) {
					font-size: 1.7rem;
				}

				@media screen and(max-width:1200px) {
					font-size: 1.6rem;
				}

				@media screen and(max-width:800px) {
					font-size: 1.5rem;
				}

				@media screen and(max-width:600px) {
					font-size: 1.4rem;
				}
			}

			@media screen and(max-width:768px) {
				text-align: center;
			}
		}

		@media screen and(max-width:768px) {
			justify-content: center;
		}
	}

	.clientTableList {
		margin: 3rem 0 4rem;
		max-height: 75rem;
		overflow: auto;

		table {
			border: 1px solid rgba(0, 0, 0, 0.2);
			width: 100%;
			table-layout: fixed;

			@media screen and (max-width: 991px) {
				border: 0px solid rgba(@secondary-color, 0.2);
			}

			thead {
				position: relative;
				clip: rect(1px, 1px, 1px, 1px);

				tr {
					text-align: left;
					border-bottom: 1px solid rgba(0, 0, 0, 0.2);

					th {
						border-right: 1px solid rgba(0, 0, 0, 0.2);
						padding: 14px;
						background-color: #f7f7ff;
						color: #000;
						font-family: @secondary-font;
						font-size: 1.7rem;
						width: 100%;
						font-weight: 400;

						@media screen and(max-width:1440px) {
							font-size: 1.5rem;
							padding: 10px 5px;
						}

						@media screen and (max-width: 991px) {
							display: block;
						}

						// &:last-child {
						// 	width: 75px;
						// }
					}

					@media screen and (max-width: 991px) {
						display: block;
					}
				}

				@media screen and (max-width: 991px) {
					position: absolute;
					overflow: hidden;
					clip: rect(1px, 1px, 1px, 1px);
				}
			}

			tbody {
				tr {
					border-bottom: 1px solid rgba(0, 0, 0, 0.5);

					td {
						padding: 14px;
						border-right: 1px solid rgba(0, 0, 0, 0.2);
						color: #000;
						font-family: @forth-banner-font;
						font-size: 1.5rem;
						font-weight: 300;
						word-wrap: break-word;

						@media screen and(max-width:1440px) {
							font-size: 1.3rem;
							padding: 10px 5px;
						}

						@media screen and (max-width: 991px) {
							display: flex;
							justify-content: space-between;
							flex-wrap: wrap;
							font-weight: 500;
							padding: 1rem 0.7rem;
							border-right: 0;
							color: black;

							&::before {
								content: attr(data-label);
							}

							span {
								text-align: right;
							}

							&:nth-child(even) {
								background-color: rgba(rgb(37, 34, 34), 0.04);
							}
						}

						@media screen and (max-width: 600px) {
							font-size: 1.2rem;
						}

						.viewImg-bth {
							.text-btn {
								svg {
									margin-left: 0;
								}
							}
						}
					}

					@media screen and (max-width: 991px) {
						border: 1px solid rgba(rgb(185, 185, 185), 0.8);
						margin-bottom: 1.5rem;
						display: block;
						border-radius: 7px;
					}
				}
			}
		}
	}

	.formBg {
		margin: 3rem 0px 3rem 0px;
		background: #f7f7ff;
		box-shadow: 0px 0.2rem 1rem 0px rgba(0, 0, 0, 0.15);
		position: relative;
		width: 100%;
		padding: 0rem 3rem;
		justify-content: space-between;
		isolation: isolate;
		display: flex;
		align-items: center;

		&:before {
			background: url("../images/tabone.svg") no-repeat top left;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 185px;
			background-size: contain;
			z-index: -1;
		}

		&::after {
			transform: rotate(180deg);
			right: 0;
			bottom: 0;
			left: auto;
			content: "";
			background: url("../images/tabone.svg") no-repeat top left;
			position: absolute;
			height: 100%;
			width: 185px;
			background-size: contain;
			z-index: -1;
		}

		form {
			display: flex;
			align-items: center;
			justify-content: space-evenly;

			label {
				font-family: @secondary-font;
				color: @secondary-color;
				font-size: 2.2rem;
				font-weight: 300;
			}

			@media screen and(max-width:991px) {
				flex-wrap: wrap;
			}
		}

		.inputField {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: nowrap;
			width: 100%;
			// flex-direction: column;

			label {
				margin: 1.2rem 0;
			}

			.ant-form-item {
				width: 100%;
				margin: 0;
				padding: 0;
				height: 100%;
				text-align: center;

				.ant-picker {
					height: 48px;
					border-radius: 40px;

					.ant-picker-input {
						input {
							&::placeholder {
								font-size: 1.4rem;
								text-align: center;
							}

							text-align: center;
						}
					}

					&.ant-picker-focused {
						box-shadow: none;
					}
				}

				.ant-form-item-control-input-content {
					.ant-select {
						.ant-select-selector {
							height: 48px;
							border-radius: 40px;

							.ant-select-selection-search {
								.ant-select-selection-search-input {
									height: 48px;
									padding-left: 10px;
									padding-right: 75px;
								}
							}

							.ant-select-selection-placeholder {
								height: 48px;
								padding-left: 10px;
								line-height: 2.3;
								color: rgba(0, 0, 0, 0.2);
								font-family: @secondary-font;
								font-size: 2rem;

								@media screen and(max-width:1400px) {
									font-size: 1.7rem;
									line-height: 2.5;
								}

								@media screen and(max-width:1200px) {
									font-size: 1.5rem;
									line-height: 2.5;
								}

								@media screen and(max-width:800px) {
									font-size: 1.2rem;
									line-height: 2.5;
								}
							}

							.ant-select-selection-item {
								line-height: 3.5;
								padding-left: 10px;
							}
						}

						.ant-select-clear {
							right: 9.5rem;
						}
					}
				}
			}

			label {
				font-family: @secondary-font;
				font-size: 2rem;
				color: @secondary-color;
				white-space: nowrap;
				margin-right: 20px;

				@media screen and(max-width:1024px) {
					font-size: 1.8rem;
				}

				@media screen and(max-width:600px) {
					font-size: 1.5rem;
				}

				@media screen and(max-width:480px) {
					font-size: 1.3rem;
				}
			}

			&.ant-input-group {
				background-color: transparent;
				// border:1px solid rgba(0,0,0,0.2);
				height: 48px;
				border-radius: 40px;

				.searchBtn {
					background: @primary-color;
					position: absolute;
					right: 0;
					border: none;
					width: 90px;
					max-width: 90px;
					border-bottom-left-radius: 40px;
					height: 100%;
					border-top-right-radius: 25px;
					border-bottom-right-radius: 25px;
				}
			}

			@media screen and(max-width:991px) {
				margin: 1rem 0px;

				.ant-input-group {
					margin: 0;
				}
			}
		}

		@media screen and(max-width:1073px) {
			padding: 5.3rem 3rem;
		}

		@media screen and(max-width:991px) {
			padding: 3.8rem 3rem;
		}

		@media screen and(max-width:983px) {
			padding: 0.2rem 3rem;
			margin: 2rem 0 0;
		}

		.pincodeForm {
			margin: 0px 100px 0px 0px;

			@media screen and(max-width:1400px) {
				margin: 0px 0px 0px 0px;
			}
		}

		.locationForm {
			margin: 0px 0px 0px 100px;

			@media screen and(max-width:1400px) {
				margin: 0px 0px 0px 0px;
			}
		}

		@media screen and(max-width:800px) {
			flex-direction: column;

			.clientHeader {
				margin: 0.2rem 0;
				padding-bottom: 0;
			}

			form {
				margin-bottom: 0rem;
			}
		}

		@media screen and(max-width:600px) {
			.formRight {
				flex-direction: column-reverse;

				.downloadAll {
					margin-bottom: 1rem;
				}
			}

			form {
				margin-bottom: 1rem;

				.inputField {
					flex-wrap: wrap;
				}
			}
		}

		@media screen and (max-width:1460px) {
			flex-wrap: wrap;
			justify-content: center;
			padding: 3rem;

			.clientHeader {
				margin: 0.5rem 0;
			}

			.formRight {
				margin-bottom: 12px;
			}
		}
	}

	@media screen and (max-width:991px) {
		.formRight {
			flex-wrap: wrap;
			justify-content: center;
			width: 100%;
		}

	}
}

.paginationData {
	margin-bottom: 50px;
	display: flex;
	justify-content: flex-end;
}

.ant-dropdown {
	width: 102px;

	ul {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		padding: 1rem;

		div {
			border: none;
			padding: 0px 0px;
		}
	}

	.ant-divider {
		margin: 0.5rem 0;
	}
}

.accountMenu {

	div,
	button {
		cursor: pointer;

		&:hover {
			color: @primary-color;
		}
	}
}

// range picker responsive

.ant-picker-dropdown {
	.ant-picker-range-wrapper {
		.ant-picker-panel-container {
			@media screen and (max-width: 580px) {
				.ant-picker-panels {
					flex-wrap: wrap;
					max-width: 300px;
				}
			}
		}
	}
}

// animation for antd btn
[ant-click-animating-without-extra-node="true"]::after {
	display: none;
}

.recordCol {
	border-right: none;
	text-align: center;
	color: @primary-color !important;

	@media screen and (max-width: 991px) {
		justify-content: center !important;
	}
}

.formRight {
	display: flex;
	align-items: center;
}

.downloadAll {
	font-size: 1.5rem;
	color: @primary-color;
	margin-right: 10px;
	cursor: pointer;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	svg {
		fill: @primary-color;
		margin-left: 10px;
		width: 30px;
		height: 30px;
	}
}

.text-btn {
	font-size: 1.5rem;
	color: @primary-color;
	margin-right: 0px;
	background: none;
	border: 0;
	outline: none;
	cursor: pointer;
	padding: 0;

	svg {
		margin-left: 10px;
		width: 24px;
		height: 24px;

		path {
			stroke: @primary-color;
			font-size: 0.08rem;
		}
	}

	@media screen and (max-width:600px) {
		font-size: 1.2rem;
	}
}

.inputText {
	.ant-select {
		box-shadow: 0 0 30px 0 rgba(0, 0, 0, .06);

		.ant-select-selector {
			height: 41px;
			border-radius: 0.8rem;

			.ant-select-selection-item {
				line-height: 3;
			}

			.ant-select-selection-search-input {
				height: 39px;
			}

			.ant-select-selection-placeholder {
				font-family: @secondary-font;
				font-size: 15px;
				line-height: 2.6;
			}
		}

		&.ant-select-focused {
			box-shadow: 0 0 0 2px rgba(237, 0, 140, .2);
			border-radius: 0.8rem;

			.ant-select-selector {
				border: none !important;
			}
		}
	}
}

// offerBtn
.offerBtn {

	.offerIcon {
		bottom: 20%;
		height: auto;
		right: 0px;
		min-height: unset;
		min-width: unset;
		position: fixed;
		z-index: 91;
		cursor: pointer;

		svg {
			width: 60px;
			height: 60px;

		}
	}
}

.offerBtnContainer {
	position: relative;

	.floatButton {
		position: fixed;
		bottom: 15%;
		height: auto;
		left: 0px;
		min-height: unset;
		min-width: unset;
		border: none;
		z-index: 91;
		cursor: pointer;
		transition: background-color 0.3s;

		svg {
			height: 60px;
			width: 60px;
		}

		@media screen and (max-height:750px) {
			bottom: 10%;
		}

		@media screen and (max-height:650px) {
			bottom: 4%;
		}

	}

	.floatAlert {
		position: fixed;
		bottom: 13%;
		left: 70px;
		background-color: #fff;
		border: 1px solid #ddd;
		border-radius: 5px;
		padding: 15px;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
		z-index: 999;

		.floatAlertContent {
			text-align: center;
			max-width: 100%;

			p {
				margin-top: 0;
				font-size: 19px;
				font-family: @secondary-font;
				margin-bottom: 0;
				color: #000;
			}

			.link {
				text-align: center;
				font-size: 19px;
				font-family: @secondary-font;
				margin-top: 0;
				margin-bottom: 0;
				font-weight: 300;
				color: @primary-color;
				cursor: pointer;

			}

			@media screen and (max-width:991px) {
				p {
					margin-top: 0;
					margin-bottom: 0;
					font-size: 15px;
				}

				.link {
					font-size: 15px;

				}
			}

			@media screen and (max-width:600px) {
				.link {
					font-size: 13px;
				}

				p {
					margin-top: 0;
					margin-bottom: 0;
					font-size: 13px;
				}
			}
		}

		.floatAlertClose {
			position: absolute;
			top: 7px;
			right: 7px;
			color: #0c0c0c;
			cursor: pointer;

			svg {
				height: 14px;
				width: 14px;
			}
		}

		@media screen and (max-height:750px) {
			bottom: 6%;
		}

		@media screen and (max-height:650px) {
			bottom: 1%;
		}
	}
}

.scrollingSection {
	.scrollingMsg {
		height: 100px;
		overflow: hidden;
		position: relative;
		top: 25px;

		div {
			font-size: 2rem;
			color: @primary-color;
			position: absolute;
			width: 100%;
			height: 100%;
			margin: 0;
			line-height: 50px;
			text-align: center;
			font-family: @primary-font;
			transform: translateX(100%);
			animation: scrolling 20s linear infinite;

			@media screen and (max-width:1024px) {
				font-size: 2rem;
			}

			@media screen and (max-width:991px) {
				font-size: 1.6rem;
			}

			@media screen and (max-width:810px) {
				font-size: 1.4rem;
				line-height: 25px;
			}
		}

		.comma {
			display: inline-block;
			margin-right: 10px;
			/* Adjust the spacing as needed */
		}
	}

	@keyframes scrolling {
		0% {
			-moz-transform: translateX(100%);
			-webkit-transform: translateX(100%);
			transform: translateX(100%);
		}

		100% {
			-moz-transform: translateX(-100%);
			-webkit-transform: translateX(-100%);
			transform: translateX(-100%);
		}
	}


}

.sendEmailModal {
	.cancelBtn {
		margin-right: 20px;
	}

	.ant-modal-body {
		.ant-form-item-control {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.captcha_relative {
		position: relative;
		display: flex;
		align-items: center;

		.captchaRelative {
			margin-bottom: 24px;
			position: relative;
			margin-right: 15px;

			.ant-form-item-control-input-content {
				.ant-input {
					color: #000;
				}
			}
		}

		.reloadIcon {
			position: absolute;
			top: 38px;
			left: 40%;

			svg {
				height: 28px;
				width: 28px;
			}
		}
	}

	.recaptchaText {
		margin: 24px 0;
	}

	.footerBtn {
		text-align: center;
		margin-top: 24px;
	}
}