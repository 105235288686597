.main__page__header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	align-items: center;
	box-shadow: 0px 16px 18px 0px rgba(0, 0, 0, 0.04);
	background-color: @white;
	//line-height: 6;
	// border-bottom: 1px solid @border-color-base;

	&.ant-layout-header {
		height: 100px;
		max-height: 100px;
	}

	.headerContainer {
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.menuContainer {
			display: flex;
			align-items: center;
			flex-direction: row;
			// flex-wrap:wrap;

			ul {
				display: flex;
				align-items: center;
				width: 100%;
				margin-bottom: 0;
				padding-left: 0px;

				// margin-left:50px;

				li {
					padding: 0px 20px;
					position: relative;

					.submenu {
						position: absolute;
						left: 0;
						top: 100%;
						flex-wrap: wrap;
						align-items: inherit;
						min-width: 250px;
						background-color: #fff;
						line-height: normal;
						display: none;
						z-index: 99;

						li {
							width: 100%;
							padding: 15px 10px;

							a {
								line-height: normal;
							}
						}
					}
					a,
					&.clnt-btn {
						color: @secondary-color;
						cursor: pointer;
						.boldFonts();
						font-size: 1.7rem;
						letter-spacing: 1.8px;
						text-transform: uppercase;
						height: 10rem;
						line-height: 9.4rem;
						.transition(0.5s, linear);

						@media screen and(max-width:1600px) {
							font-size: 1.5rem;
						}

						@media screen and(max-width:1450px) {
							font-size: 1.3rem;
						}

						@media screen and(max-width:1100px) {
							font-size: 1.2rem;
						}
						&.active {
							color: @primary-color;
						}
					}

					&::after {
						display: none;
					}

					&:hover {
						>a,
						&.clnt-btn {
							color: @primary-color;
						}
					}

					@media screen and(max-width:1600px) {
						padding: 0px 14px;
					}

					@media screen and(max-width:1450px) {
						padding: 0px 8px;
					}

					@media screen and(max-width:1300px) {
						padding: 0px 8px;
					}

					@media screen and(max-width:1200px) {
						padding: 0px 5px;
					}
					&:hover {
						>.submenu {
							display: block;
						}
					}
				}

				.ant-menu-item {
					padding: 0 20px;

					.ant-menu-title-content {
						color: @secondary-color;
						.boldFonts();
						font-size: 1.7rem;
						letter-spacing: 1.8px;
						text-transform: uppercase;
						.transition(0.5s, linear);

						@media screen and (max-width: 1290px) {
							font-size: 1.4rem;
						}
					}

					&::after {
						display: none;
					}

					&:hover {
						.ant-menu-title-content {
							color: @primary-color;
						}
					}

					&.ant-menu-item-active,
					&.ant-menu-item-selected {
						.ant-menu-title-content {
							color: @primary-color;
						}
					}
				}

				@media screen and(max-width:1191px) {
					padding-left: 0px;
				}
			}

			.headerBtn {
				margin-right: 10px;

				button {
					@media screen and(max-width:1500px) {
						padding: 6px 15px;
						font-size: 1.6rem;
					}

					@media screen and(max-width:1400px) {
						padding: 6px 13px;
						font-size: 1.4rem;
					}

					@media screen and(max-width:1100px) {
						padding: 9px 13px 6px;
						font-size: 1.2rem;
						margin-right: 0;
					}
				}
			}

			@media screen and(max-width:991px) {
				display: none;
			}

			.userIcon {
				border-radius: 50%;
				padding: 0px 0px;
				height: 30px;
				width: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: -10px;
				margin-left: 10px;

				// background-color:@secondary-color;

				svg {
					height: 22px;
					width: 22px;

					g {
						path {
							fill: @primary-color;
						}
					}
				}
			}

			&:hover {
				background-color: transparent;

				svg {
					g {
						path {
							fill: @secondary-color;
						}
					}
				}
			}
		}

		.socialIcons {
			margin-top: -27px;

			ul {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				padding: 0px;
				gap: 15px;

				li {
					display: flex;
					align-items: center;
					justify-content: center;

					margin: 0px 0px 0px 0px;
					text-align: center;
					.transition(0.5s, ease-in-out);

					a {
						background-color: @grey-color;
						width: 48px;
						height: 50px;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 0px 0px 50px 50px;

						.icon {
							width: 22px;
							height: 22px;
							padding: 0;
							margin: 0;
						}

						svg.icon {
							path {
								fill: @secondary-color;
							}
						}

						>&:hover {
							background: linear-gradient(315deg,
								#ed008c 0%,
								#ff86cd 100%);

							.icon {
								filter: invert(100%);
							}

							svg.icon {
								path {
									fill: @white;
								}
							}
						}

						@media screen and (max-width: 1460px) {
							width: 38px;
						}

						// @media screen and(max-width:1191px) {
						// 	width: 28px;
						// 	height: 35px;
						// }

						svg {
							@media screen and(max-width:1191px) {
								height: 15px;
								width: 15px;
							}
						}
					}
				}

				@media screen and(max-width:1100px) {
					gap: 8px;
					margin-bottom: 1.1rem;
				}
			}

			.followPageLink {
				width: 100%;
				line-height: 1;
				text-align: center;

				span {
					font-family: @mild-font;
					font-weight: 600;
					font-size: 1.6rem;
					letter-spacing: 2px;
					color: @secondary-color;
					text-transform: uppercase;

					@media screen and(max-width:1300px) {
						font-size: 1.3rem;
					}

					@media screen and(max-width:1100px) {
						font-size: 1.2rem;
					}
				}
			}

			@media screen and(max-width:1191px) {
				margin-top: -38px;
			}

			@media screen and(max-width:991px) {
				display: none;
			}
		}
	}

	.menuToggle {
		visibility: hidden;
		position: absolute;
		top: 18px;
		right: 20px;

		svg {
			height: 0;
			width: 0;

			@media screen and(max-width:991px) {
				height: 35px;
				width: 35px;
			}
		}

		@media screen and(max-width:991px) {
			visibility: visible;
		}

		// @media screen and(max-width:600px) {
		// 	top: 0.3rem;
		// }

		// @media screen and(max-width:480px) {
		// 	top: 0.5rem;
		// 	right: 1.5rem;
		// }
	}
}

.logininfo {
	width: 385px;
	display: block;
	background: @white;
	position: absolute;
	right: 0;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	.clientInfo {
		display: flex;
		align-items: center;
		padding: 0 1rem 1rem;
		margin-bottom: 0rem;
		.clientIcon {
			width: 100px;
			height:100px;
			border: 2px solid @primary-color;
			border-radius: 50%;
			justify-content: center;
			align-items: center;
			display: flex;
			.ant-avatar {
				height: 100%;
				width: 100%;
			}
		}
		.clientDetails {
			padding: 0 0 0 3rem;
			ul {
				padding: 0;
				margin: 0;
			}
		}
	}
	ul {
		&.accountMenu {
			flex-direction: row;
			flex-wrap: wrap;
			padding: 1.5rem 0 0;
			box-shadow: none;
			li {
				width: calc(50% - 10px);
				border-right: 0px solid #6e6e6e;
				padding: 0.5rem 0rem;
				cursor: pointer;
				display: inline-flex;
				column-gap: 10px;
				&:nth-last-of-type(even) {
					border-right: 2px solid #6e6e6e;
					margin-right: 15px;
					height: 36px;
				}
				&:hover {
					color: @primary-color;
				}
			}
		}
	}
}

.isSticky {
	background-color: @white;
	box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
	position: fixed;
	transition: all 0.4s ease;
	z-index: 999;
}

.responsiveDrawer {
	height: auto;
	top: 100px;

	.ant-drawer-content-wrapper {
		width: 100% !important;
		height: 500px;
		overflow: auto;
	}

	.ant-drawer-header {
		padding: 0px 0px;

		.ant-drawer-close {
			position: absolute;
			z-index: 999;
			right: 0;
			top: 20px;
			color: white;
			display: none;
		}
	}

	.responsiveMenu {
		position: absolute;
		width: 100%;
		background: #efefef;
		z-index: 99;
		height: 620px;
		overflow: auto;
		text-align: center;
		// top:100px;
		// position:absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		visibility: hidden;
		transition: all 0.7s ease-in-out;

		@media screen and(max-width:991px) {
			visibility: visible;
		}

		.menuWrapper {
			padding-left: 0;
			margin-top: 0px;

			li {
				padding: 10px 25px;
				line-height: 1.5;
				text-align: left;
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);

				a {
					color: @secondary-color;
					.boldFonts();
					font-size: 1.7rem;
					letter-spacing: 1.8px;
					text-transform: uppercase;
					.transition(0.5s, linear);
					position: relative;
					width: 100%;
					display: block;
					span.arrow {
						position: absolute;
						right: 0;
						top: 0;
					}

				}
				&:hover{
					a{
						color: @primary-color;
					}
				}
				&.active{
					a{
						color: @primary-color;
					}
				}
				&:nth-child(5){
					a{
						color:@black;
					}
				}
			}

			.clientMenu {
				padding: 10px 25px;
				line-height: 1.5;
				text-align: left;
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);
				color: @secondary-color;
				.boldFonts();
				font-size: 1.7rem;
				letter-spacing: 1.8px;
				text-transform: uppercase;
				.transition(0.5s, linear);
			}
			.submenu{
				li{
					&.active{
						a{
							color:@primary-color;
						}
					}
				}
			}
		}

		@media screen and(max-width:1200px) {
			.clientlLogin {
				.commanBtn {
					padding: 0.8rem 1.8rem !important;
					font-size: 1.5rem !important;
				}
			}
		}

		.socialIcons {
			ul {
				padding-left: 25px;
				display: flex;
				// justify-content: center;

				align-items: center;

				li {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0px 15px 0px 0px;

					a {
						width: 40px;
						height: 40px;
						border: 1px solid @secondary-color;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 50%;

						&:hover {
							border: 1px solid @primary-color;

							img {
								filter: invert(16%) sepia(98%) saturate(7171%) hue-rotate(317deg) brightness(94%) contrast(102%);
							}
						}
					}
				}
			}
		}

		.followPageLink {
			display: none;
		}

		.clientBtn {
			text-align: left;
			margin: 1rem 0 2rem 2.2rem;
		}

		ul.submenu {
			margin: 0;
			padding: 0;
			// display: none;

			li {
				padding: 10px 10px 0 10px;
				border: none;
				a {
					font-size: 1.4rem;
				}
				*::selection {
					background: transparent;
					color: inherit;
				}
			}
		}
	}
}