.deliverModal{
	
		.ant-modal-close {
			color: @white;
		}
	
	.ant-modal-header {
		background-color: @primary-color;
		.ant-modal-title {
			color: #fff;
			font-family: @primary-font;
			font-size: 1.9rem;
			// @media screen and (max-width: 600px) {
			// 	font-size: 1.6rem;
			// }
		}
	}
	.ant-modal-body{
		padding: 1rem 0;
		.customScrollbarTrack {
			background-color: #efefef;
			height: 100%;
			right: 2px;
			top: 0;
			border-radius: 3rem;
		}
		.customScrollbarThumb {
			border-radius: 2rem;
			background: #ed008c;
			width: 1.2rem;
		}
		.scrollBar {
			.scrollBox {
				height: 435px;
				padding-inline: 1rem 2rem;
				@media screen and (max-height:480px) {
					height: 335px;
					
				}
			}
		}
	}
	
.delModalTimeline {
	&.ant-timeline.ant-timeline-label {
		padding: 2rem;
	
		overflow: auto;
    .ant-row {margin-left: 0; margin-right: 0;}
		.ant-timeline-item-label {
			width: 36px;
			height: 36px;
			top: 0;
			text-align: center;
			background-color: @primary-color;
			display: flex;
			justify-content: center;
			align-items: center;
			color: @white;
			z-index: 1;
			border-radius: 36px;
		}
		.ant-timeline-item-tail,
		.ant-timeline-item-head {
			left: 17px;
			border-color: rgba(@text-input-color,0.5)
		}
    .ant-timeline-item-last > .ant-timeline-item-tail{
      display: block;
      height: calc(100% - 30px);
    }
		.ant-timeline-item-content {
			position: relative;
			top: 0;
			margin: 0;
			word-break: break-word;
			left: 70px;
			width: calc(100% - 72px);
			padding-right: 0;
			margin-left: 0;
			right: 0;
			.ant-row {
				border: 1px solid rgba(0, 0, 0, 0.2);
				border-bottom: 0;
				border-right: 0;
      
				.ant-col {
					padding: 8px 10px;
					border-right: 1px solid rgba(0, 0, 0, 0.2);
					border-bottom: 1px solid rgba(0, 0, 0, 0.2);
					font-size: 1.6rem;
					font-family: "Poppins", sans-serif;
          color: @black;
          &:nth-child(even){
            color: @text-color;
          }
				}
			}
		}
    @media screen and (max-width:600px) {
      padding: 0rem;    
      .ant-timeline-item-label{
        width: 30px;
        height: 30px;
        //font-size: 1rem;
      }
      .ant-timeline-item-tail,
		.ant-timeline-item-head {
			left: 14px;
		}
      .ant-timeline-item-content{
        left: 60px;
        
        // .ant-row .ant-col{
        //   font-size: 1rem;
        // }
      }  
    }
    @media screen and (max-width:400px) {

      .ant-timeline-item-content{
        left: 48px;
        width: calc(100% - 58px);
      }
    }

	}
}
}